import { Action } from '@ngrx/store';

import { ILogin } from '../model/user.model';
import { IStaffSession } from '../model/staff.model';

export enum EStaffActions {
  LoginStaffAssociate = '[STAFF] Login Staff Associate',
  LoginStaffAssociateSuccess = '[STAFF] Login Staff Associate Success',
}

export class StaffAction implements Action {
  readonly type: string;
  staffAssociateSession?: IStaffSession;
}

export class LoginStaffAssociate implements StaffAction {
  public readonly type = EStaffActions.LoginStaffAssociate;
  constructor(public payload: ILogin) { }
}

export class LoginStaffAssociateSuccess implements StaffAction {
  public readonly type = EStaffActions.LoginStaffAssociateSuccess;
  constructor(public staffAssociateSession: IStaffSession, public role: string) { }
}

export type StaffActions =
  | LoginStaffAssociate
  | LoginStaffAssociateSuccess;
