import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap, mergeMap, catchError, tap
} from 'rxjs/operators';

import { ShowSuccessMsg, ShowErrorMessage } from '../app.actions';

import { resolvePostLoginNavigation } from '../users/users.effects';
import { LoginStaffAssociate, EStaffActions, LoginStaffAssociateSuccess } from './staff.actions';
import { StaffService } from './staff.service';
import { IStaffSession } from '../model/staff.model';

@Injectable()
export class StaffEffects {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private staffService: StaffService,
  ) { }

  // @Effect()
  loginStaffAssociate$ = createEffect(() => this.actions$.pipe(
    ofType<LoginStaffAssociate>(EStaffActions.LoginStaffAssociate),
    switchMap(({ payload }: LoginStaffAssociate) => this.staffService
      .loginStaffAssociateFromService(payload).pipe(
        tap(staff => resolvePostLoginNavigation(staff, this.router, this.route.snapshot.queryParams.returnUrl)),
        mergeMap((staff: IStaffSession) => [
          new LoginStaffAssociateSuccess(staff, staff.role),
          new ShowSuccessMsg('Login Successful'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));
}
