import {
  ValidatorFn, FormGroup, ValidationErrors, AbstractControl
} from '@angular/forms';

export const PasswordMatchValidator: ValidatorFn = (
  fb: FormGroup
): ValidationErrors | null => {
  const newPassword = (fb.get('password')) ? fb.get('password') : fb.get('newPassword');
  const confirmPassword = fb.get('confirmPassword');

  return newPassword
    && confirmPassword
    && newPassword.value !== confirmPassword.value
    ? { passwordNotMatch: true }
    : null;
};

export const PasswordNotMatchValidator: ValidatorFn = (
  fb: FormGroup
): ValidationErrors | null => {
  const existingPassword = fb.get('existingPassword');
  const newPassword = fb.get('newPassword');

  return newPassword
    && existingPassword
    && newPassword.value === existingPassword.value
    ? { passwordMatched: true }
    : null;
};

export const CheckExist = (myArray: any[]): ValidatorFn => (c: AbstractControl): { [key: string]: boolean } | null => {
  const selectboxValue = c.value;
  const pickedOrNot = myArray.filter(
    (alias) => alias.name === selectboxValue
  );

  if (pickedOrNot.length > 0) {
    // everything's fine. return no error. therefore it's null.
    return null;
  }
  // there's no matching selectboxvalue selected. so return match error.
  return { match: true };
};

export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = '^[a-zA-Z \-\']+',
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
  GRADE = '^[a-zA-Z0-9]*$',
  NAME = '^[a-zA-Z ]*$'
}

export const filterArray = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

export function dateCheckValidator(fromDateKey: string, toDateKey: string) {
  return (group: FormGroup) => {
    const fromDate = group.controls[fromDateKey];
    const toDate = group.controls[toDateKey];
    if (fromDate && toDate
      && (new Date(toDate.value).getTime() <= new Date(fromDate.value).getTime())) {
      return toDate.setErrors({ dateError: true });
    }

    if (new Date(toDate.value).getTime() > new Date(fromDate.value).getTime()) {
      if (toDate.hasError('dateError')) {
        return toDate.setErrors(null);
      }
    }
  };
}
