import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';

import { userQuery } from 'src/app/store/users/users.selectors';
import { LoginSuccess, ChangeRole } from 'src/app/store/users/users.actions';
import { ERole, Recruiter } from 'src/app/store/model/user.model';
import { recruiterQuery } from 'src/app/store/recruiter/recruiter.selectors';
import { LoginRecruiterSuccess } from 'src/app/store/recruiter/recruiter.actions';
import { IRecruiterSession } from 'src/app/store/model/recruiter.model';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private store: Store,
    private sharedService: SharedService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser;
    let role: string;
    localStorage.setItem('university', 'HUB');
    localStorage.removeItem('isSeperateExploreUniversities');
    // localStorage.setItem('university', 'UWLA');
    role = localStorage.getItem('role') ? localStorage.getItem('role') : null;
    if (role === ERole[ERole.STUDENT]) {
      localStorage.setItem('loginRole', 'STUDENT');
      this.store.pipe(select(userQuery.userSelect)).subscribe((user) => {
        currentUser = user;
        this.sharedService.loadStudentDocuments(); /* Load Student Documents */
      });
    } else if (Recruiter(localStorage.getItem('role')) === 'RECRUITER') {
      localStorage.setItem('loginRole', 'RECRUITER');
      this.store.pipe(select(recruiterQuery.recruiterSessionSelect)).subscribe((user: IRecruiterSession) => {
        currentUser = user;
      });
    }

    if (!currentUser) {
      currentUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      role = localStorage.getItem('role') ? localStorage.getItem('role') : null;
      this.store.dispatch(new ChangeRole(role));

      if (role === ERole[ERole.STUDENT]) {
        this.store.dispatch(new LoginSuccess(role, currentUser));
      } else {
        this.store.dispatch(new LoginRecruiterSuccess(currentUser, role));
      }
    }

    if (currentUser) {
      return true;
    }
    
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
    if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'STUDENT')) {
      this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
    } else if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'RECRUITER')) {
      this.router.navigate(['authentication/recruiter/login'], { queryParams: { returnUrl: state.url } });
    }
    return false;
  }
}
