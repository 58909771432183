import { IStudentManagementState, initialStudentMangementState } from './student-management.selectors';
import { StudentManagementActions, EStudentManagementActions } from './student-management.actions';

export const StudentManagementReducers = (
  state: IStudentManagementState = initialStudentMangementState,
  action: StudentManagementActions
): IStudentManagementState => {
  switch (action.type) {
    case EStudentManagementActions.GetStudentListSuccess: {
      return {
        ...state,
        studentList: action.studentList
      };
    }

    case EStudentManagementActions.GetInviteFailedListSuccess: {
      return {
        ...state,
        failedList: action.failedList
      };
    }

    case EStudentManagementActions.DeleteFromInviteFailedSuccess: {
      return {
        ...state,
        deleteFailedList: action.deleteFailedList
      };
    }

    case EStudentManagementActions.GetStudentProfileSuccess: {
      return {
        ...state,
        studentProfile: action.studentProfile
      };
    }

    case EStudentManagementActions.GetExploreUniversitySuccess: {
      return {
        ...state,
        exploreUniversity: action.exploreUniversity
      };
    }

    case EStudentManagementActions.DashboardMetrics: {
      return {
        ...state,
        dashboardMetrics: action.dashboardMetrics
      };
    }
    case EStudentManagementActions.LeadsbyCard: {
      return {
        ...state,
        leadsbyCard: action.leadsbyCard
      };
    }

    case EStudentManagementActions.ApplyCourseSuccess: {
      return {
        ...state,
        applyCourseDetails: action.applyCourseSuccess
      };
    }

    case EStudentManagementActions.GetApplicationStatusSuccess: {
      return {
        ...state,
        applicationStatus: action.applicationStatus
      };
    }

    case EStudentManagementActions.GetDegreeListSuccess: {
      return {
        ...state,
        degreeList: action.degreeList
      };
    }

    case EStudentManagementActions.CreateStudentSuccess: {
      return {
        ...state,
        createStudentInfo: action.createStudentInfo
      };
    }
    case EStudentManagementActions.ResetCreateStudentSuccess: {
      return {
        ...state,
        createStudentInfo: null
      };
    }

    case EStudentManagementActions.GetUniversityListSuccess: {
      return {
        ...state,
        universityList: action.universityList
      };
    }
    case EStudentManagementActions.GetUniversityListSuccessV2: {
      return {
        ...state,
        universityList: action.universityList
      };
    }

    case EStudentManagementActions.GetCourseListSuccess: {
      return {
        ...state,
        courseList: action.courseList
      };
    }

    case EStudentManagementActions.GetAppliedApplicationsSuccess: {
      return {
        ...state,
        appliedApplications: action.appliedApplications
      };
    }

    case EStudentManagementActions.GetShortlistedApplicationsSuccess: {
      return {
        ...state,
        shortlistedApplications: action.shortlistedApplications
      };
    }

    case EStudentManagementActions.StudentDocuments: {
      return {
        ...state,
        documents: action.documents
      };
    }

    case EStudentManagementActions.PayNowSuccess: {
      return {
        ...state,
        payNow: action.payNow
      };
    }

    case EStudentManagementActions.UniversityProposedPaymentStatusSuccess: {
      return {
        ...state,
        proposedPaymentStatus: action.proposedPaymentStatus
      };
    }

    case EStudentManagementActions.GetSaveUniversityApplicationsSuccess: {
      return {
        ...state,
        saveUniversityApplications: action.savedUniversity
      };
    }

    case EStudentManagementActions.GetStudentApplicationStatusSuccess: {
      return {
        ...state,
        studentApplicationStatus: action.applicationStatus
      };
    }

    case EStudentManagementActions.UpdateDocumentCompletionSuccess: {
      return {
        ...state,
        documentCompletion: action.documentCompletion
      };
    }

    case EStudentManagementActions.GetStudentNotificationSuccess: {
      return {
        ...state,
        studentNotification: action.studentNotification
      };
    }

    case EStudentManagementActions.GetStudentSavedFavoriteUniversityListSuccess: {
      return {
        ...state,
        savedStudentFavoriteUniversity: action.savedStudentFavoriteUniversity
      };
    }

    default:
      return state;
  }
};
