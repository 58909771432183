import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  })
export class AppComponent implements OnInit {
  public title = 'University HUB';
  options = {
    lastOnBottom: false,
    position: ['bottom', 'right'],
    timeOut: 20000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: false,
    maxLength: 10,
  };
  constructor() {

  }

  ngOnInit(): void {
  }
}
