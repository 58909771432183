import {
  IInviteStudent,
  IRecruiterSession,
  IRecruiterRequest,
  IStaffList,
  IBankingInformation
} from '../model/recruiter.model';

export interface IRecruiterState {
  recruiter: IRecruiterSession;
  inviteStudent: IInviteStudent;
  recruiterProfile: IRecruiterRequest,
  recruiterList: any;
  staffList: IStaffList[];
  bankingInformation: IBankingInformation;
  commissionPolicy: any;
  recruiterNotification: any;
  recruiterAssociateNotification: any;
  getRecruiterStudentShorlistedApplication: any;
  soundList: any;
}

export const initialRecruiterState: IRecruiterState = {
  recruiter: null,
  inviteStudent: null,
  recruiterProfile: null,
  recruiterList: null,
  staffList: null,
  bankingInformation: null,
  commissionPolicy: null,
  recruiterNotification: null,
  recruiterAssociateNotification: null,
  getRecruiterStudentShorlistedApplication: null,
  soundList: null
};
