import { IUser } from '../model/user.model';
import { IRecruiterSession } from '../model/recruiter.model';

export interface IUserState {
  user: IUser; // Student
  role: string;
  loggedInUser: IUser | IRecruiterSession; // Student/Recruiter
}

export const initialUserState: IUserState = {
  role: null,
  user: null,
  loggedInUser: null
};
