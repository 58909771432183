<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center">
    <!-- <div id="icon-circle" [style.border-color]="status.color"> -->
      <mat-icon [style.color]="status.color">{{status.icon}}</mat-icon>
    <!-- </div> -->
    <p class="m-b-10" id="header-size"> {{title}} </p>
    <p class="text-center ws-pl"> {{message}} </p>
  </div>

  <div fxLayoutAlign="center center" fxLayoutGap="12px" class="m-t-20">
    <button mat-raised-button class="bg-danger text-white" mat-dialog-close>
      {{cancelButtonText}}
    </button>
    <button mat-raised-button class="bg-success text-white" (click)="onConfirmClick()">
      {{confirmButtonText}}
    </button>
  </div>
</mat-dialog-content>