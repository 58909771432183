import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, NgControl, ValidatorFn, Validators
} from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { SharedService } from '../../services/shared.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-mmyyyydateformat',
  templateUrl: './mmyyyydateformat.component.html',
  styleUrls: ['./mmyyyydateformat.component.scss'],
  providers: [
  {
  provide: DateAdapter,
  useClass: MomentDateAdapter,
  deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
  })
export class MmyyyydateformatComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() requiredMessage: string;
  @Input() submitClicked: boolean;
  @Output() optionSelected = new EventEmitter();
  inputControl = new FormControl(null, [this.validators]);
  @Input() testDateMaxDate = moment();
  @Input() testDateMinDate = moment().subtract(60, 'years').format();
  public onTouched = () => { };
  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: SharedService,
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }
  writeValue(obj: any): void {
    obj ? obj && this.inputControl.setValue(moment(obj)) : this.inputControl.setValue('');
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.submitClicked) {
      this.submitClicked = changes.submitClicked.currentValue;
      if (this.submitClicked === true) {
        this.inputControl.markAllAsTouched();
      }
    }
    if (changes.testDateMinDate) {
      this.testDateMinDate = moment(changes.testDateMinDate.currentValue).startOf('year').format();
    }
  }
  ngOnDestroy() {
    if (this.optionSelected) {
      this.optionSelected.unsubscribe();
    }
  }
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker?: any) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.inputControl.setValue(ctrlValue);
    this.optionSelected.emit(this.inputControl.value);
  }

  private get validators(): ValidatorFn {
    return Validators.required;
  }
}
