<div class="simple-notification-wrapper" [ngClass]="position">
    <app-notifications
            *ngFor="let a of notifications; let i = index"
            [item]="a"
            [timeOut]="timeOut"
            [clickToClose]="clickToClose"
            [clickIconToClose]="clickIconToClose"
            [maxLength]="maxLength"
            [showProgressBar]="showProgressBar"
            [pauseOnHover]="pauseOnHover"
            [theClass]="theClass"
            [rtl]="rtl"
            [animate]="animate"
            [position]="i">
    </app-notifications>
</div>

