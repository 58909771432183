import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EReducers } from '../app.state';
import { IRecruiterState } from './recruiter.state';

export const recruiterSelectorFeature = createFeatureSelector<IRecruiterState>(
  EReducers[EReducers.RECRUITER]
);

const recruiterSessionSelect = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state?.recruiter
);

export const recruiterProfileSelect = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.recruiterProfile
);

const recruiterListSelect = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.recruiterList
);

const staffListSelect = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.staffList
);

const bankingInformation = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.bankingInformation
);

const commissionPolicy = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.commissionPolicy
);

const recruiterNotification = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.recruiterNotification
);

const recruiterAssociateNotification = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.recruiterAssociateNotification
);

const getRecruiterStudentShorlistedApplication = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.getRecruiterStudentShorlistedApplication
);

const soundList = createSelector(
  recruiterSelectorFeature,
  (state: IRecruiterState) => state.soundList
);

export const recruiterQuery = {
  recruiterSessionSelect,
  recruiterProfileSelect,
  recruiterListSelect,
  staffListSelect,
  bankingInformation,
  commissionPolicy,
  recruiterNotification,
  recruiterAssociateNotification,
  getRecruiterStudentShorlistedApplication,
  soundList
};

// export const recruiterSelector = createSelector(
//     recruiterSelectorFeature,
//     role => role === ERole[ERole.RECRUITER]
// );
