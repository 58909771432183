import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { IAppState } from './app.state';
import { UserReducers } from './users/users.reducers';
import { RecruiterReducers } from './recruiter/recruiter.reducers';
import { StudentManagementReducers } from './student-management/student-management.reducers';
import { StaffReducers } from './staff/staff.reducers';

export const AppReducers: ActionReducerMap<IAppState, any> = {
  ROUTER: routerReducer,
  USER: UserReducers,
  RECRUITER: RecruiterReducers,
  STUDENT_MANAGEMENT: StudentManagementReducers,
  STAFF: StaffReducers
};
