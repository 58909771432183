import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ERole, Recruiter } from 'src/app/store/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ExploreUniversityGuard  {
  constructor() { }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const role = localStorage.getItem('role') ? localStorage.getItem('role') : null;
    if (Recruiter(role) === 'RECRUITER' || role === 'ASSOCIATE') {
      localStorage.setItem('isRecruiterExploreUniversities', 'exploreUniversities');
    } else if (role !== ERole[ERole.STUDENT]) {
      localStorage.setItem('isSeperateExploreUniversities', 'exploreUniversities');
    }
    return true;
  }
}
