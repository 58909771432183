import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { io } from 'socket.io-client';

import { environment } from 'src/environments/environment';

@Injectable()
export class SocketService {
  private socket: any;

  private baseUrl = environment.baseUrl;

  public user = localStorage.getItem('user');

  private socketUrl = this.baseUrl.replace('/studentUniversity', '');
  constructor() {
    this.socket = io(this.socketUrl, {
      query: { salesforceId: JSON.parse(this.user).salesforceId }
    });
  }

  public getStudentNotification() {
    const observable = new Observable(observer => {
      this.socket.on('student-notification', (data) => {
        observer.next(data);
      });
    });
    return observable;
  }

  public getRecruiterNotification() {
    const observable = new Observable(observer => {
      this.socket.on('recruiter-notification', (data) => {
        observer.next(data);
      });
    });
    return observable;
  }
  public getRecruiterAssociateNotification() {
    const observable = new Observable(observer => {
      this.socket.on('recruiter-associate-notification', (data) => {
        observer.next(data);
      });
    });
    return observable;
  }
}
