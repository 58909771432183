<!-- <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="12px">
  <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between">
    <div class="close-icon">
      <button mat-icon-button color="primary" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="12px">
      <div style="width: 125px;height: 85px;">
        <img class="university-logo" [src]="universityLogo ? universityLogo : ''"
          [title]="universityName ? universityName : ''" alt="University Logo" />
      </div>
      <div fxLayout="column" fxLayoutAlign="center">
        <h4 class="h3-align app-main-heading">{{ universityName ? universityName : ''}}</h4>
      </div>
    </div>
  </div>
  <div fxFlex>
    <mat-dialog-content class="scroll-content">
      <mat-card class="margin-15-0">
        <ng-container>
          <mat-card-content class="p-0" fxLayoutGap="20px" fxLayout="column" fxLayout.gt-md="row"
            style="overflow: auto;">
            <div fxFlex="25" class="second-segment-scroll">
              <div fxFlex="100" class="m-10 second-segment app-content" fxLayout="column" fxLayoutGap="15px">
                <div fxFlex fxLayout="column" fxLayoutGap="15px">
                  <div fxFlex="35" fxLayout="column" fxLayoutAlign="center" style="word-break: break-word;">
                    {{ universityWebsite ? universityWebsite: '' }}
                  </div>

                  <div fxFlex fxLayout="column" fxLayoutAlign="center">
                    {{ universityAddress ? universityAddress : '' }}
                  </div>
                  <mat-divider></mat-divider>
                </div>
                <div fxFlex>
                  <p>
                    {{ universityDescription ? universityDescription : '' }}
                  </p>
                </div>
              </div>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="responsive-table responsive-second-table" fxFlex="75">

              <table mat-table [dataSource]="universityDetailsDatasource">

                <ng-container matColumnDef="course">
                  <th mat-header-cell *matHeaderCellDef> {{'COURSE' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.course }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="degree">
                  <th mat-header-cell *matHeaderCellDef> {{'DEGREE' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.degree }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> {{'ACTION' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <div *ngIf="reachedMax">
                      <button disabled mat-flat-button class="text-white"
                        [ngClass]="isApplied ? 'bg-secondary' : (isShortlist[i] ? 'bg-danger':'bg-secondary')">
                        {{ isShortlist[i] ? 'Shortlisted' : 'Shortlist' }}
                      </button>
                    </div>
                    <div *ngIf="!reachedMax">
                      <button mat-flat-button class="text-white" (click)="onClickShortListUniversity(element,i)"
                        [disabled]="isShortlist[i] || isApplied"
                        [ngClass]="isApplied ? 'bg-secondary' : (isShortlist[i] ? 'bg-danger':'bg-success')">
                        {{ isShortlist[i] ? 'Shortlisted' : 'Shortlist' }}
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="universityDetailsColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: universityDetailsColumns;"></tr>
              </table>


            </div>
          </mat-card-content>

          <mat-card-actions align="end" class="p-r-15 p-b-15">

          </mat-card-actions>
        </ng-container>
      </mat-card>
    </mat-dialog-content>
  </div>
</div> -->

<div class="popup-inner">
  <div class="row">
    <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="12px">
      <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between">
        <div class="close-icon">
          <button mat-icon-button color="primary" (click)="dialogRef.close()">
            <mat-icon style="color:#860e21">close</mat-icon>
          </button>
        </div>

        <div class="d-flex">
          <div class="mr-2 ml-2">
            <img class="university-logo logo-block" [src]="universityLogo ? universityLogo : ''"
          [title]="universityName ? universityName : ''" alt="University Logo" />
          </div>
          <div class="mt-2">
            <div class="header-lg boldtext">{{ universityName ? universityName : ''}}</div>
            Address:<br>
            {{ universityAddress ? universityAddress : '' }}
          </div>
        </div>
        <div class="clr"></div>
    </div>
    </div>
    <!-- <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="12px"> -->
      <!-- <div style="width: 125px;height: 85px;">
        <img class="university-logo"  [src]="universityLogo ? universityLogo : ''"
          [title]="universityName ? universityName : ''" alt="University Logo" />
      </div> -->
      <!-- <div fxLayout="column" fxLayoutAlign="center">
        <h1 class="h3-align app-main-heading" > -->
          <!-- <a [href]="dialogData?.website ? dialogData?.website : ''"
            target="_blank" *ngIf="dialogData?.website">
            {{ universityName ? universityName : ''}}
          </a>
          <div *ngIf="!dialogData?.website">
            {{ universityName ? universityName : ''}}
          </div> -->
          <!-- {{ universityName ? universityName : ''}}
        </h1>
      </div> -->
    <!-- </div> -->
   
  <div class="row">
    <mat-dialog-content class="scroll-content">
      <mat-tab-group mat-align-tabs="left" dynamicHeight fxLayout="column" fxFlex="100">
        <mat-tab label="Details" fxFlex="100" class="semi-bold">
          <div style="height: 100%;overflow-y: auto;">
            <div fxLayout="column" class="app-content second-tab" fxLayoutGap="10px">
              <div class="p-2 bodyfont">
                <span class="semi-bold">About:<br></span> {{ universityDescription ? universityDescription : '' }}
              </div>
              <mat-divider></mat-divider>
              <div fxLayout="column" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" class="bodyfont m-2" >
                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="10px" class="bodyfont p-2" >
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" class="m-2">
                    <mat-icon>monetization_on</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center" >
                      <div class="semi-bold">
                        Application fee
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.detailLevelApplicationFees ? dialogData?.detailLevelApplicationFees : 'N/A'}}
                      </div>
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <mat-icon>assignment_ind</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div class="semi-bold">
                        Mode of Learning
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.modeOfLearning ? dialogData?.modeOfLearning : 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" class="bodyfont m-2 p-2">
                  <!-- <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <div class="fontawesome-div" fxLayoutAlign="center center">
                      <i class="fa fa-book fa-lg" style="margin-left: 5px"></i>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div>
                        GRE/GMAT Requirement
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.isGreGmat ? dialogData?.isGreGmat : 'N/A'}}
                      </div>
                    </div>
                  </div> -->
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" >
                    <mat-icon>assignment</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div class="semi-bold">
                        Min GPA
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.minGPA ? dialogData?.minGPA : 'N/A'}}
                      </div>
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <mat-icon>school</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div class="semi-bold">
                        Intake
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.tileLevelIntake ? dialogData?.tileLevelIntake : 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" class="bodyfont m-2 p-2">
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <mat-icon>home</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div class="semi-bold">
                        Living Cost
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.detailLevelLivingCost ? dialogData?.detailLevelLivingCost : 'N/A'}}
                      </div>
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <mat-icon>account_balance</mat-icon>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div class="semi-bold">
                        Number of campus
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.detailLevelNumberOfCampus ? dialogData?.detailLevelNumberOfCampus : 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" class="bodyfont m-2">
                    <div class="fontawesome-div">
                      <i class="fa fa-book fa-lg" style="margin-left: 10px"></i>
                    </div>
                    <div fxLayout="row" >
                      <div class="semi-bold">
                        English Proficiency Requirement
                      </div>
                      <div fxFlex="1 2 calc(15em + 20px)" > 
                      &nbsp;:{{dialogData?.detailLevelEnglishProficiency ? dialogData?.detailLevelEnglishProficiency : 'N/A'}}
                      
                      </div>
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <mat-icon>monetization_on</mat-icon>
                    <div fxLayout="row" >
                      <div class="semi-bold">
                        Tuition fee
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.tuitionFees ? dialogData?.tuitionFees : 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                  <div fxFlex="50" fxLayout="row" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                    <div class="fontawesome-div" fxLayoutAlign="center center">
                      <i class="fa fa-book fa-lg" style="margin-left: 5px"></i>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div>
                        English Proficiency Requirement
                      </div>
                      <div>
                      &nbsp;: {{dialogData?.detailLevelEnglishProficiency ? dialogData?.detailLevelEnglishProficiency : 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="bodyfont p-2">
                <small style="font-size: 13px;" *ngIf="role === 'STUDENT'"><span class="semi-bold">* Note: </span><span > Application fee may Vary. Please write to us at 'applications@universityhub.com'</span></small>
                <small style="font-size: 13px;" *ngIf="role !== 'STUDENT'"><span class="semi-bold">* Note:</span> Application fee may Vary. Please reach us at 'recruiters@universityhub.com'</small>              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Courses" fxFlex="100" class="semi-bold"> 
          <div style="height: 100%;">
            <div class="responsive-table responsive-second-table">
              <table mat-table [dataSource]="universityDetailsDatasource">    
                <ng-container matColumnDef="course">
                  <th mat-header-cell *matHeaderCellDef class="semi-bold"> {{'COURSE' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.course }}
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="degree">
                  <th mat-header-cell *matHeaderCellDef class="semi-bold"> {{'DEGREE' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.degree }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="fullTimeTuitionFees">
                  <th mat-header-cell *matHeaderCellDef> {{'Full Time' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullTimeTuitionFees ? element.fullTimeTuitionFees : 'N/A' }}
                  </td>
                </ng-container> -->

                <ng-container matColumnDef="internationalTuitionFees">
                  <th mat-header-cell *matHeaderCellDef class="semi-bold"> {{'Tuition Fees' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.internationalTuitionFees ? element.internationalTuitionFees : 'N/A' }}
                    <mat-icon *ngIf="element.noOfCreditHours"  style="cursor: default;" [matTooltip]="'Total Credit Hours : ' + element.noOfCreditHours">info
                    </mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="STEMProgram">
                  <th mat-header-cell *matHeaderCellDef class="semi-bold"> STEM Program </th>
                  <td mat-cell *matCellDef="let element">
                    <button style="cursor: default;" mat-icon-button *ngIf="element.isSTEMProgram && element.isSTEMProgram === 'Yes'">
                      <mat-icon class="text-success">check_circle</mat-icon>
                    </button>

                    <button style="cursor: default;" mat-icon-button *ngIf="element.isSTEMProgram && element.isSTEMProgram === 'No'">
                      <mat-icon class="text-primary">cancel_outline</mat-icon>
                    </button>
                    <div *ngIf="!element.isSTEMProgram" class="p-10">N/A</div>
                    <!-- {{ element.isSTEMProgram ? element.isSTEMProgram : 'N/A' }} -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="isGreGmat">
                  <th mat-header-cell *matHeaderCellDef>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="semi-bold">GRE/GMAT</div>
                      <mat-icon matTooltip="GRE requirement depends on GPA acheived and course taken. Please contact admissions team for more information."
                        class="color-black">
                      info
                    </mat-icon>
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <button style="cursor: default;" mat-icon-button *ngIf="element.isGreGmat && element.isGreGmat === 'Yes'">
                      <mat-icon class="text-success">check_circle</mat-icon>
                    </button>

                    <button style="cursor: default;" mat-icon-button *ngIf="element.isGreGmat && element.isGreGmat === 'No'">
                      <mat-icon class="text-primary">cancel_outline</mat-icon>
                    </button>
                    <div *ngIf="!element.isGreGmat" class="p-10">N/A</div>
                    <!-- {{ element.isGreGmat ? element.isGreGmat : 'N/A' }} -->
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="semi-bold"> {{'ACTION' | titlecase}} </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <div *ngIf="reachedMax">
                      <button disabled mat-flat-button class="text-white"
                        [ngClass]=" isShortlist[i] ? 'bg-primary':'bg-secondary' ">
                        {{ isShortlist[i] ? 'Shortlisted' : 'Shortlist' }}
                      </button>
                    </div>
                    <div *ngIf="!reachedMax">
                      <button mat-flat-button class="text-white" (click)="onClickShortListUniversity(element,i)"
                       
                        [ngClass]="isShortlist[i] ? 'bg-primary':'bg-success'">
                        {{ isShortlist[i] ? 'Shortlisted' : 'Shortlist' }}
                      </button>
                      
                    </div>
                  </td>
                </ng-container>
                <!-- <button mat-flat-button class="text-white" (click)="onClickShortListUniversity(element,i)"
                        [disabled]="isShortlist[i] || isApplied"
                        [ngClass]="isApplied ? 'bg-secondary' : (isShortlist[i] ? 'bg-primary':'bg-success')">
                        {{ isShortlist[i] ? 'Shortlisted' : 'Shortlist' }}
                      </button> -->

                <!-- Header Application Fees group -->
                <!-- <ng-container *ngFor="let firstHeader of topHeaderColumn; let i = index;" [matColumnDef]="firstHeader">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="i===2 ? 2 : 1" style="text-align: center !important;">
                    {{ (i===2) ? 'Tuition Fees' : '' }}
                  </th>
                </ng-container> -->

                <!-- <tr mat-header-row *matHeaderRowDef="topHeaderColumn;"></tr> -->
                <tr mat-header-row *matHeaderRowDef="universityDetailsColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: universityDetailsColumns;"></tr>
              </table>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </div>
  </div>
</div>
