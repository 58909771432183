import {
  IInviteStudent,
} from '../model/recruiter.model';
import { IStaffSession } from '../model/staff.model';

export interface IStaffState {
  staffAssociateSession: IStaffSession;
  inviteStudent: IInviteStudent;
  staffProfile: any,
}

export const initialStaffState: IStaffState = {
  staffAssociateSession: null,
  inviteStudent: null,
  staffProfile: null,
};
