import { Component, Inject, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShowErrorMessage, ShowSuccessMsg } from 'src/app/store/app.actions';
import { Store, select } from '@ngrx/store';
import { FileUploadService } from '../../services/file-upload.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-add-custom-doc',
  templateUrl: './add-custom-doc.component.html',
  styleUrls: ['./add-custom-doc.component.scss']
  })
export class AddCustomDocComponent implements OnInit {
  fileMulti: any[] = [];
  customDocName: string;
  docUploadError: string;
  docUploadError2 = 'Select files to upload.';
  customFileList: any = [];
  customDocLeadId: any;
  currentGuid: any;
  user: any;
  currentProject: any;
  customDocList: any;
  sidenavData: any;
  constructor(public dialogRef: MatDialogRef<AddCustomDocComponent>,
    private store: Store,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService, @Inject(MAT_DIALOG_DATA) private dialogData: any) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.fileMulti = [];
    this.customDocName = '';
    this.currentGuid = this.dialogData.GUID;
    if (this.dialogData.projId == 0) {
      const universityInfo = this.sharedService.allUniversity();
      const index = universityInfo.findIndex(x => x.name == this.dialogData.uniName);
      this.dialogData.projId = universityInfo[index].id;
    }
    this.currentProject = this.dialogData.projId;
    this.sidenavData = this.dialogData.sidenavData;
    this.dialogData.customDocList.forEach(element => {
      if (element.name == 'Statement of Purpose') {
        this.sidenavData.documentList.forEach(x => {
          if (x.ProgramDoc == 'Statement of Purpose') {
            if (x.TransferUniversity == this.dialogData.uniName) {
              element.disabled = true;
            }
          }
        });
      }
    });
    this.dialogData.customDocList.sort((x, y) => Number(x.disabled) - Number(y.disabled));
    this.customDocList = this.dialogData.customDocList;
    // ======== GET UNIVERSITY INFO

    this.fileUploadService.getUniversityInfoByName({
      university: this.dialogData.uniName
    }).subscribe((data: any) => {
    }, (err) => {

    });

    // getAllCustomDocs
  }
  uploadCustomDocument(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      this.fileMulti.push(e.target.files[i]);
    }
    if (this.fileMulti.length > 0) {
      e.target.value = '';
    }
  }

  addCustomDocument() {
    this.docUploadError = '';
    if (this.customDocName == '') {
      this.docUploadError = 'Document name cannot be empty!';
    } else {
      const check = true;
      this.fileMulti.forEach((element) => {
        if (this.validateFile(element.type) == false) {
          this.store.dispatch(new ShowErrorMessage('Invalid file format!'));
        }
      });
      if (check == true) {
        const formData: FormData = new FormData();
        formData.append('GUID', this.currentGuid);
        formData.append('docName', this.customDocName);
        formData.append('Platform', 'Recruiter');
        formData.append('MailId', this.user.emailId);
        formData.append('ProjectId', this.currentProject);

        Array.from(this.fileMulti).forEach((file) => formData.append('document', file, file.name));
        this.fileUploadService.uploadCustomDocument(formData).subscribe(
          (res: any) => {
            // let response = res.map((d:any) =>{status: d.status});
            const response = res.status;
            if (response == 200) {
              if (this.dialogData.isTransfer) {
                this.updatetransferUniversityProg(res.StudentdocId);
              }
              this.customFileList.push({
                docName: this.customDocName,
                fileName: '',
              });
              this.docUploadError = 'Custom document added';
              this.store.dispatch(new ShowSuccessMsg('Custom document added!'));
            } else if (response == 201) {
              if (this.dialogData.isTransfer) {
                this.updatetransferUniversityProg(res.StudentdocId);
              }
              // this.customFileList.push({
              //   docName: this.customDocName,
              //   fileName: this.file.name,
              // });
              this.docUploadError = 'Uploaded a custom document';
              this.store.dispatch(new ShowSuccessMsg('Uploaded a custom document!'));
            } else if (response == 202) {
              this.docUploadError = 'Document with given name already exists!';
              this.store.dispatch(new ShowErrorMessage('Document with given name already exists!'));
              return;
            }
            setTimeout(() => {
              this.fileMulti = [];
              this.customDocName = '';
              this.dialogRef.close();
            }, 1000);
          },
          (error) => {
          }
        );
      }
    }
  }
  // Validate Uploaded file
  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'image/jpeg') {
      return true;
    } if (ext.toLowerCase() == 'document') {
      return true;
    } if (ext.toLowerCase() == 'image/png') {
      return true;
    } if (
      name.substring(name.lastIndexOf('/') + 1).toLowerCase() == 'pdf'
    ) {
      return true;
    }
    this.store.dispatch(new ShowErrorMessage('Please upload a valid file!'));
    return false;
  }
  clearData() {
    this.fileMulti = [];
    this.customDocName = '';
  }
  updatetransferUniversityProg(docId) {
    if (this.dialogData.uniName && this.dialogData.uniPrg) {
      this.fileUploadService.updateTransferCustomDoc({
        studentdocid: docId,
        university: this.dialogData.uniName,
        program: this.dialogData.uniPrg
      }).subscribe((data: any) => {

      }, (err) => {

      });
    }
  }
}
