// This is for Notifications
export interface INotifications {
  round: string;
  icon: string;
  subject: string;
  message: string;
  time: string;
  redirectUrl:string;
  studentSalesforceId: string;
}

// export const notifications: INotifications[] = [
//   {
//     round: 'round-danger',
//     icon: 'ti-link',
//     subject: 'Launch Admin',
//     message: 'Just see the my new admin!',
//     time: '9:30 AM'
//   },
//   {
//     round: 'round-success',
//     icon: 'ti-calendar',
//     subject: 'Event today',
//     message: 'Just a reminder that you have event',
//     time: '9:10 AM'
//   },
//   {
//     round: 'round-info',
//     icon: 'ti-settings',
//     subject: 'Settings',
//     message: 'You can customize this template as you want',
//     time: '9:08 AM'
//   },
//   {
//     round: 'round-primary',
//     icon: 'ti-user',
//     subject: 'Pavan kumar',
//     message: 'Just see the my admin!',
//     time: '9:00 AM'
//   }
// ];

// This is for Mymessages
export const mymessages: Object[] = [
  {
    useravatar: 'assets/images/users/1.jpg',
    status: 'online',
    from: 'Pavan kumar',
    subject: 'Just see the my admin!',
    time: '9:30 AM'
  },
  {
    useravatar: 'assets/images/users/2.jpg',
    status: 'busy',
    from: 'Sonu Nigam',
    subject: 'I have sung a song! See you at',
    time: '9:10 AM'
  },
  {
    useravatar: 'assets/images/users/2.jpg',
    status: 'away',
    from: 'Arijit Sinh',
    subject: 'I am a singer!',
    time: '9:08 AM'
  },
  {
    useravatar: 'assets/images/users/4.jpg',
    status: 'offline',
    from: 'Pavan kumar',
    subject: 'Just see the my admin!',
    time: '9:00 AM'
  }
];
