import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { ERole } from './store/model/user.model';
import { recruiterRoles } from './shared/models/shared.model';
import { ExploreUniversityComponent } from './pages/student/explore-university/explore-university.component';
import { ExploreUniversityGuard } from './shared/guards/explore-university.guard';
import { EllisComponent } from './pages/ellis/ellis.component';
import { ProfileCompletionGuard } from './shared/guards/profile-completion.guard';

const routes: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/recruiter/login',
        pathMatch: 'full'
      },
      {
        path: 'recruiter',
        redirectTo: '/authentication/recruiter/login',
        pathMatch: 'full'
      },
      {
        path: 'authentication',
        loadChildren:
          () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
        canActivate: [AuthGuard, ProfileCompletionGuard],
      },
      {
        path: 'changepassword',
        loadChildren: () => import('./pages/authentication/change-password/change-password.module').then(m => m.ChangePasswordModule),
        canActivate: [AuthGuard, ProfileCompletionGuard],
      },
      {
        path: 'student-management',
        loadChildren: () => import('./pages/recruiter/student-management/student-management.module').then(m => m.StudentManagementModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'recruiter-profile',
        loadChildren: () => import('./pages/recruiter/recruiter-profile/recruiter-profile.module').then(m => m.RecruiterProfileModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'manage-staff',
        loadChildren: () => import('./pages/recruiter/recruiter-management/recruiter-management.module').then(m => m.RecruiterManagementModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'commission-policy',
        loadChildren: () => import('./pages/recruiter/commission-policy/commission-policy.module').then(m => m.CommissionPolicyModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'school-commission',
        loadChildren: () => import('./pages/recruiter/school-commission/school-commission.module').then(m => m.SchoolCommissionModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'banking-information',
        loadChildren: () => import('./pages/recruiter/banking-information/banking-information.module').then(m => m.BankingInformationModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: recruiterRoles }
      },
      {
        path: 'student-profile',
        loadChildren: () => import('./pages/student/student-application/student-application.module').then(m => m.StudentApplicationModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [ERole[ERole.STUDENT]] }
      },
      {
        path: 'documents',
        loadChildren: () => import('./pages/student/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: [ERole[ERole.STUDENT]] }
      },
      {
        path: 'student-dashboard',
        loadChildren: () => import('./pages/student/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [ERole[ERole.STUDENT]] }
      },
      {
        path: 'application',
        loadChildren: () => import('./pages/student/application/application.module').then(m => m.ApplicationModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: [ERole[ERole.STUDENT]] }
      },
      {
        path: 'universities',
        loadChildren: () => import('./pages/student/universities/universities.module').then(m => m.UniversitiesModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
        data: { roles: [ERole[ERole.STUDENT]] }
      },
      {
        path: 'payment-student',
        loadChildren: () => import('./pages/student/payment/payment.module').then(m => m.PaymentModule),
        canActivate: [AuthGuard, ProfileCompletionGuard],
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
        canActivate: [AuthGuard, ProfileCompletionGuard],
      },
      {
        path: 'mobileandbanking',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
        canActivate: [AuthGuard, ProfileCompletionGuard],
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'explore-universities',
        loadChildren: () => import('./pages/student/explore-university/explore-university.module').then(m => m.ExploreUniversityModule),
        canActivate: [AuthGuard, ProfileCompletionGuard]
      },
      {
        path: 'application-status',
        loadChildren: () => import('./pages/student/application-status/application-status.module').then(m => m.ApplicationStatusModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard],
      },
      {
        path: 'recruiter-student-profile',
        loadChildren: () => import('./pages/recruiter/student-management/student-profile/student-profile.module').then(m => m.StudentProfileModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-create-student',
        loadChildren: () => import('./pages/recruiter/create-student-recruiter/create-student-recruiter.module').then(m => m.CreateStudentRecruiterModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-student-document',
        loadChildren: () => import('./pages/recruiter/student-management/student-document/student-document.module').then(m => m.StudentDocumentModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-explore-universities',
        loadChildren: () => import('./pages/student/explore-university/explore-university.module').then(m => m.ExploreUniversityModule),
        canActivate: [ExploreUniversityGuard, AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-shortlisted-universities',
        loadChildren: () => import('./pages/recruiter/student-management/student-shortlisted-universities/student-shortlisted-universities.module').then(m => m.StudentShortlistedUniversitiesModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-explore-institutes',
        loadChildren: () => import('./pages/recruiter/student-management/student-explore-universities/student-explore-universities.module').then(m => m.StudentExploreUniversitiesModule),
        canActivate: [AuthGuard, RoleGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-student-payment',
        loadChildren: () => import('./pages/recruiter/student-management/student-payment/student-payment.module').then(m => m.StudentPaymentModule),
        canActivate: [AuthGuard, ProfileCompletionGuard]
      },
      {
        path: 'recruiter-student-application-status',
        loadChildren: () => import('./pages/recruiter/student-management/student-application-status/student-application-status.module').then(m => m.StudentApplicationStatusModule),
        canActivate: [AuthGuard, ProfileCompletionGuard]
      },
      {
        path: 'mobile',
        component: EllisComponent,
        canActivate: [AuthGuard, ProfileCompletionGuard]
      }
    ]
  },
  {
    path: 'explore',
    component: ExploreUniversityComponent,
    canActivate: [ExploreUniversityGuard]
  },
  {
    path: '**',
    redirectTo: '/authentication/recruiter/login'
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
    })],
  exports: [RouterModule],
  })
export class AppRoutingModule { }
