import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';

import { finalize } from 'rxjs/operators';

import { PlatformLocation } from '@angular/common';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor {
  private requests: HttpRequest<any>[] = [];
  private totalRequests = 0;
  private currentUrl : any;

  constructor(
    private loaderService: LoaderService,
    private pLocation: PlatformLocation,

  ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.loaderSubject.next(this.requests.length > 0);
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler) {
  //   this.totalRequests++;
  //   this.loaderService.loaderSubject.next(true);

  //   return next.handle(request).pipe(
  //     catchError(() => of(this.removeRequest(request))),
  //     finalize(() => {
  //       this.removeRequest(request);
  //     })
  //   );
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // this.currentUrl = location.origin + this.pLocation.getBaseHrefFromDOM();
    this.currentUrl = window.location.href;
    this.totalRequests++;
    this.loaderService.loaderSubject.next(true);

    // if (this.currentUrl.split('/').includes('recruiter-student-profile')) {
    //   this.loaderService.spinnerFullSubject.next(true);
    // }

    return next.handle(request).pipe(
      // catchError((error) => {
      //   this.totalRequests--;
      //   if (this.totalRequests === 0) {
      //     this.loaderService.loaderSubject.next(false);
      //   }
      //   return throwError(error);
      // }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loaderService.loaderSubject.next(false);
          // this.loaderService.spinnerFullSubject.next(false);
        }
      })
    );
  }
}

/*
    return Observable.create(observer => next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          this.removeRequest(req);
          observer.next(event);
        }
      }),
      catchError((err) => {
        this.removeRequest(req);
        return observer.error(err);
      }),
      finalize(() => {
        this.removeRequest(req);
        observer.complete();
      })
    ).subscribe());
  }
*/
