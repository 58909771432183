import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { initializeApp } from 'firebase/app';
import { FlexLayoutModule } from '@angular/flex-layout';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppReducers } from './store/app.reducers';
import { AppEffects } from './store/app.effects';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderFullComponent } from './shared/components/loader/loader-full.component';
import { AngularMaterialModule } from './shared/modules/angular-material.module';
import { SharedModule } from './shared/shared.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { FilterDialogBoxComponent } from './shared/filter-dialog-box/filter-dialog-box.component';
import { EllisComponent } from './pages/ellis/ellis.component';
import { CreateStudentRecruiterComponent } from './pages/recruiter/create-student-recruiter/create-student-recruiter.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
  AppComponent,
  LoaderComponent,
  LoaderFullComponent,
  FilterDialogBoxComponent,
  EllisComponent,
  CreateStudentRecruiterComponent
  ],
  imports: [
  CommonModule,
  AngularMaterialModule,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  HttpClientModule,
  SharedModule,
  AppRoutingModule,
  LayoutsModule,
  StoreModule.forRoot(AppReducers),
  EffectsModule.forRoot(AppEffects),
  StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
  !environment.production ? StoreDevtoolsModule : []
  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  })
export class AppModule { }
