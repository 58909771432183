<div class="container create-student" fxFlex="100" style="margin-bottom: 60px;">
    <span class="page_name">Add Student</span>

    <div id="stepperSection">

    </div>
    <div id="forms">

        <div id="studentInfoForm">
            <mat-card appearance="outlined" class="testing-card">
                <mat-card-header>
                    <mat-card-title>
                        Student Info
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="p-t-0">
                    <form [formGroup]="studentInfoform">
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100" fxLayoutGap="20px">
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>Student Name Details <span>*</span> </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <input matInput type="text" formControlName="firstName"
                                                placeholder="First Name">
                                            <mat-error
                                                *ngIf="studentInfoform.controls['firstName'].hasError('required') && studentInfoform.controls['firstName'].touched">
                                                First name field is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['firstName'].hasError('pattern') && studentInfoform.controls['firstName'].touched">
                                                Please enter only alphabets.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['firstName'].hasError('maxlength') && studentInfoform.controls['firstName'].touched">
                                                Maximum 50 characters allowed.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['firstName'].hasError('minlength') && studentInfoform.controls['firstName'].touched">
                                                Please enter at least 3 characters.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>
                                                <span style="opacity: 0;">Last Name</span>
                                            </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <input matInput type="text" formControlName="lastName"
                                                placeholder="Last Name">
                                            <mat-error
                                                *ngIf="studentInfoform.controls['lastName'].hasError('required') && studentInfoform.controls['lastName'].touched">
                                                Last name field is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['lastName'].hasError('pattern') && studentInfoform.controls['lastName'].touched">
                                                Please enter only alphabets.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['lastName'].hasError('maxlength') && studentInfoform.controls['lastName'].touched">
                                                Maximum 50 characters allowed.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['lastName'].hasError('minlength') && studentInfoform.controls['lastName'].touched">
                                                Please enter at least 3 characters.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100" fxLayoutGap="20px">
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div style="flex-direction: column;
                                    box-sizing: border-box;
                                    display: flex;">
                                        <div class="field-label">
                                            <mat-label>Contact Number <span>*</span> </mat-label>
                                        </div>
                                        <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="5px">
                                            <div fxFlex.gt-sm="48" fxFlex="100">
                                                <app-auto-complete-country-code placeholder="Country Code"
                                                    formControlName="phoneCountryCode"
                                                    requiredMessage="Please enter your country."
                                                    countryMessage="Please enter a valid country."
                                                    [submitClicked]="submitClicked" [fullArrayOption]="fullCountryArray"
                                                    [options]="phonecountryOrganizationAutocompleteObservable | async"
                                                    (click)="onClickSelectAutocompleteField()"
                                                    (optionSelected)="onSelectAutocompleteCode($event,'phoneCountryCode')">
                                                </app-auto-complete-country-code>
                                            </div>
                                            <div fxFlex.gt-sm="49" fxFlex="100">
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" formControlName="phoneWithoutCode"
                                                        placeholder="Phone Number">
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['phoneWithoutCode'].hasError('required') && studentInfoform.controls['phoneWithoutCode'].touched">
                                                        Phone number field is required.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['phoneWithoutCode'].hasError('pattern') && studentInfoform.controls['phoneWithoutCode'].touched">
                                                        Please enter only numeric values.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['phoneWithoutCode'].hasError('maxlength') && studentInfoform.controls['phoneWithoutCode'].touched">
                                                        Please enter no more than 17 characters.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['phoneWithoutCode'].hasError('minlength') && studentInfoform.controls['phoneWithoutCode'].touched">
                                                        Please enter at least 4 characters.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div style="flex-direction: column;
                                    box-sizing: border-box;
                                    display: flex;">
                                        <div class="field-label">
                                            <mat-label>Emergency Number</mat-label>
                                        </div>
                                        <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="5px">
                                            <div fxFlex.gt-sm="48" fxFlex="100">
                                                <app-auto-complete-country-code placeholder="Country Code"
                                                    formControlName="emergencyPhoneCountryCode"
                                                    requiredMessage="null"
                                                    countryMessage="Please enter a valid country."
                                                    [submitClicked]="submitClicked" [fullArrayOption]="fullCountryArray"
                                                    [options]="emergencycountryOrganizationAutocompleteObservable | async"
                                                    (click)="onClickSelectAutocompleteField()"
                                                    (optionSelected)="onSelectAutocompleteCode($event,'emergencyPhoneCountryCode')">
                                                </app-auto-complete-country-code>
                                            </div>
                                            <div fxFlex.gt-sm="48" fxFlex="100">
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text"
                                                        formControlName="emergencyPhoneWithoutCode"
                                                        placeholder="Phone Number">

                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['emergencyPhoneWithoutCode'].hasError('required') && studentInfoform.controls['emergencyPhoneWithoutCode'].touched">
                                                        Phone number field is required
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['emergencyPhoneWithoutCode'].hasError('pattern') && studentInfoform.controls['emergencyPhoneWithoutCode'].touched">
                                                        Please enter only numeric values.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['emergencyPhoneWithoutCode'].hasError('maxlength') && studentInfoform.controls['emergencyPhoneWithoutCode'].touched">
                                                        Please enter no more than 17 characters.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="studentInfoform.controls['emergencyPhoneWithoutCode'].hasError('minlength') && studentInfoform.controls['emergencyPhoneWithoutCode'].touched">
                                                        Please enter at least 4 characters.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100" fxLayoutGap="20px">
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div #dobDatePicker>
                                        <div class="field-label">
                                            <mat-label>DOB <span>*</span> </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <input matInput type="text" formControlName="dateOfBirth"
                                                placeholder="DD/MM/YYYY" [matDatepicker]="dateOfBirthPicker"
                                                [max]="DobMaxDate" (focus)="dateOfBirthPicker.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['dateOfBirth'].hasError('required') && studentInfoform.controls['dateOfBirth'].touched">
                                                Date of Birth field is required .
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>
                                                Email <span>*</span>
                                            </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <input matInput type="text" formControlName="emailId" placeholder="Email">
                                            <mat-error
                                                *ngIf="studentInfoform.controls['emailId'].hasError('required') && studentInfoform.controls['emailId'].touched">
                                                Email field is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['emailId'].hasError('pattern') && studentInfoform.controls['emailId'].touched">
                                                Please enter a valid email address.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100" fxLayoutGap="20px">
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>Country <span>*</span> </mat-label>
                                        </div>
                                        <app-auto-complete-country placeholder="Country" formControlName="country"
                                            requiredMessage="Country field is required"
                                            countryMessage="Please choose your country from the list"
                                            [submitClicked]="submitClicked" [fullArrayOption]="fullCountryArray"
                                            [options]="countryOrganizationAutocompleteObservable | async"
                                            (click)="onClickSelectAutocompleteField()"
                                            (optionSelected)="onSelectAutocomplete($event)">
                                        </app-auto-complete-country>
                                    </div>
                                </div>
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>Student Type <span>*</span> </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">

                                            <mat-select placeholder="Student Type" formControlName="type"
                                                (selectionChange)="typeChange($event.value)">
                                                <mat-option value="" selected disabled>
                                                    Please select student type
                                                </mat-option>
                                                <mat-option value="transfer" matTooltip="transfer">
                                                    Transfer
                                                </mat-option>
                                                <mat-option value="international" matTooltip="international">
                                                    International
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['type'].hasError('required') && studentInfoform.controls['type'].touched">
                                                Please select the student type
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100" fxLayoutGap="20px">
                                <!-- 
                                    /*
                                    *********************************************************************
                                    ****** DATE: 18 Oct 2023
                                    ****** REASON: Client comment : Password Field: Remove or Hide
                                    ****** OLD CODE:
                                    <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div #dobDatePicker>
                                        <div class="field-label">
                                            <mat-label>Password</mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <input matInput type="text" formControlName="password"
                                                placeholder="Password">
                                            <mat-error
                                                *ngIf="studentInfoform.controls['password'].hasError('required') && studentInfoform.controls['password'].touched">
                                                Password field is required .
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                    *********************************************************************
                                    */

                                 -->
                                <div fxFlex.gt-sm="40" fxFlex="100">
                                    <div>
                                        <div class="field-label">
                                            <mat-label>
                                                Level of study <span>*</span>
                                            </mat-label>
                                        </div>
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <!-- <input matInput type="text" formControlName="emailId"
                                                placeholder="youremail@email.com"> -->
                                            <mat-select formControlName="levelOfStudy" placeholder="Level of study">
                                                <!-- 
                                                    /*
                                                        *********************************************************************
                                                        ****** DATE: 18 Oct 2023
                                                        ****** REASON: Level of Study: Undergraduate | Graduate (Masters) | Doctorate
                                                        ****** OLD CODE:
                                                        <mat-option value="Under Graduate">
                                                            Under Graduate
                                                        </mat-option>
                                                        <mat-option value="Graduate">
                                                            Graduate
                                                        </mat-option>
                                                        <mat-option value="Post Graduate">
                                                            Post Graduate
                                                        </mat-option>
                                                        *********************************************************************
                                                        */
                                                 -->
                                                <mat-option value="Undergraduate">Undergraduate</mat-option>
                                                <mat-option value="Graduate (Masters)">Graduate (Masters)</mat-option>
                                                <mat-option value="Doctorate">Doctorate</mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="studentInfoform.controls['levelOfStudy'].hasError('required') && studentInfoform.controls['levelOfStudy'].touched">
                                                Please select a level of study from the list.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>

        <div id="universityInfoForm">
            <mat-card appearance="outlined" class="testing-card">
                <mat-card-header>
                    <mat-card-title>
                        University
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="p-t-0">
                    <form [formGroup]="studentInfoform">
                        <div class="m-t-b-45">
                            <div formArrayName="universityInfo">
                                <div *ngFor="let uni of universityInfo.controls; let i=index">
                                    <div fxLayout="row wrap" fxLayoutGap="10px" [formGroupName]="i"
                                        class="position_relative">
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div class="field-label">
                                                <mat-label>
                                                    Univeristy Name <span>*</span>
                                                </mat-label>
                                            </div>
                                            <app-auto-complete-university placeholder="University"
                                                formControlName="name"
                                                requiredMessage="Please choose the university from the list."
                                                programMessage="Please choose the university from the list."
                                                [submitClicked]="submitClicked" [fullArrayOption]="UniversityListArray"
                                                [options]="universityAutocompleteObservable[i] | async"
                                                (click)="onClickSelectAutocompleteField()"
                                                (optionSelected)="onSelectAutocompleteUniversity($event,i)">
                                            </app-auto-complete-university>

                                        </div>
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div class="field-label">
                                                <mat-label>
                                                    Program Name <span>*</span>
                                                </mat-label>
                                            </div>
                                            <app-auto-complete-program placeholder="Program Name"
                                                formControlName="program"
                                                requiredMessage="Please select the program name."
                                                programMessage="Please select the program name."
                                                [submitClicked]="submitClicked" [fullArrayOption]="fullProgramArray"
                                                [currentForm]="universityInfo.controls"
                                                [options]="programAutocompleteObservable[i] | async"
                                                (click)="onClickSelectAutocompleteFieldProgram(uni)"
                                                (optionSelected)="onSelectAutocompleteCodeProgram($event,i)">
                                            </app-auto-complete-program>
                                        </div>
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div class="field-label">
                                                <mat-label>
                                                    Term <span>*</span>
                                                </mat-label>
                                            </div>
                                            <app-auto-complete-terms placeholder="Term" formControlName="term"
                                                requiredMessage="Please select the desired term."
                                                programMessage="Please select the desired term."
                                                [submitClicked]="submitClicked" [fullArrayOption]="fullTermArray"
                                                [options]="termsAutocompleteObservable[i] | async"
                                                (click)="onClickSelectAutocompleteFieldTerm(uni)"
                                                (optionSelected)="onSelectAutocompleteCodeTerm($event,i)">

                                            </app-auto-complete-terms>
                                        </div>
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div class="field-label">
                                                <mat-label>
                                                    Year <span>*</span>
                                                </mat-label>
                                            </div>
                                            <app-mmyyyydateformat placeholder="MM/YYYY" formControlName="year"
                                                    [testDateMaxDate]="uniDateMaxDate" [testDateMinDate]="uniDateMinDate"
                                                    requiredMessage="Please select year."
                                                    [submitClicked]="submitClicked"
                                                    (optionSelected)="onSelectYearFrom($event,i,'universityInfo','year')">
                                                </app-mmyyyydateformat>
                                        </div>
                                        <div class="position_absolute close-button" (click)="removeUniversityInfo(i)">
                                            <div class="close-icon">
                                                <mat-icon style="color: #A5A5A5;">cancel</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="addMoreUniversity">
                                <button type="button" mat-raised-button color="primary" (click)="addUniversityInfo()"
                                    style="background-color: #0A6EBD;"> + Add More </button>
                            </div>
                        </div>
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxFlex="100">
                                <div class="field-label">
                                    <mat-label>
                                        Language Test
                                        <br><small>Please choose the test with your highest score.</small>
                                    </mat-label>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="50px">
                                    <mat-checkbox class="app-content"
                                        (click)="addlanguageTestInfo('Duolingo')">Duolingo</mat-checkbox>
                                    <mat-checkbox class="app-content"
                                        (click)="addlanguageTestInfo('IELTS')">IELTS</mat-checkbox>
                                    <mat-checkbox class="app-content"
                                        (click)="addlanguageTestInfo('PTE')">PTE</mat-checkbox>
                                    <mat-checkbox class="app-content"
                                        (click)="addlanguageTestInfo('TOEFL')">TOEFL</mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="m-t-b-45">
                            <div formArrayName="languageTestInfo">
                                <div *ngFor="let lang of languageTestInfo.controls; let i=index">
                                    <div fxLayout="row wrap" fxLayoutGap="20px" [formGroupName]="i">
                                        <div fxFlex="100"><mat-label>{{lang.value.type}} <span>*</span></mat-label> </div>
                                        <div fxFlex.gt-sm="30" fxFlex="100">
                                            <!-- <div class="field-label">
                                                <mat-label>
                                                    <span style="opacity: 0;">Test Date</span>
                                                </mat-label>
                                            </div> -->
                                            <mat-form-field style="width: 100%" appearance="outline">
                                                <input matInput type="text" placeholder="DD/MM/YYYY"
                                                    [matDatepicker]="dateOfBirthPickerUni"
                                                    (focus)="dateOfBirthPickerUni.open()" formControlName="date"
                                                    [max]="testDateMaxDate">
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="dateOfBirthPickerUni"></mat-datepicker-toggle>
                                                <mat-datepicker #dateOfBirthPickerUni></mat-datepicker>
                                                <mat-error
                                                    *ngIf="lang.controls['date'].hasError('required') && lang.controls['date'].touched">
                                                    Please enter the test date.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex.gt-sm="30" fxFlex="100">
                                            <!-- <div class="field-label">
                                                <mat-label>
                                                    <span style="opacity: 0;">Score</span>
                                                </mat-label>
                                            </div> -->
                                            <mat-form-field style="width: 100%" appearance="outline">
                                                <input matInput type="text" placeholder="Score" formControlName="score">
                                                <mat-error
                                                    *ngIf="lang.controls['score'].hasError('required') && lang.controls['score'].touched">
                                                    Score field is required.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="lang.controls['score'].hasError('pattern') && lang.controls['score'].touched">
                                                    Please enter valid score
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="lang.controls['score'].hasError('minlength') && lang.controls['score'].touched">
                                                    Please enter atleast 1 character
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="lang.controls['score'].hasError('maxlength') && lang.controls['score'].touched">
                                                    Maximum 3 characters required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>

        <div id="academicInfoForm">
            <mat-card appearance="outlined" class="testing-card">
                <mat-card-header>
                    <mat-card-title>
                        Academic
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="p-t-0">
                    <form [formGroup]="studentInfoform">
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxFlex="100">
                                <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="30px">
                                    <mat-checkbox class="app-content" *ngFor="let item of academicType"
                                        (click)="addacademicTypeInfo(item.value)">{{item.name}}</mat-checkbox>
                                </div>
                            </div>
                            <div formArrayName="academicTypeInfo">
                                <!-- <div *ngFor="let lang of academicTypeInfo.controls; let i=index">

                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px"
                                        class="academic-type-fields" [formGroupName]="i">
                                        <div fxFlex="100" class="font-bold">{{getAcademicTypeInfo(lang.value.type)}}
                                        </div>
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div>
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" placeholder="Institution"
                                                        formControlName="name">
                                                    <mat-error
                                                        *ngIf="lang.controls['name'].hasError('required') && lang.controls['name'].touched">
                                                        Please enter the name of your degree.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['name'].hasError('pattern') && lang.controls['name'].touched">
                                                        Please enter valid name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-sm="15" fxFlex="100">
                                            <div>
                                                <app-mmyyyydateformat placeholder="MM/YYYY" formControlName="yearFrom"
                                                    [testDateMaxDate]="testDateMaxDate" [testDateMinDate]=""
                                                    requiredMessage="Please enter the start date."
                                                    [submitClicked]="submitClicked"
                                                    (optionSelected)="onSelectYearFrom($event,i,'academicTypeInfo','yearFrom')">
                                                </app-mmyyyydateformat>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-sm="15" fxFlex="100">
                                            <div>
                                                <app-mmyyyydateformat placeholder="MM/YYYY" formControlName="yearTo"
                                                    requiredMessage="Please enter the end date."
                                                    [testDateMaxDate]="testDateMaxDate"
                                                    [testDateMinDate]="lang.controls['yearFrom'].value"
                                                    [submitClicked]="submitClicked"
                                                    (optionSelected)="onSelectYearFrom($event,i,'academicTypeInfo','yearTo')">
                                                </app-mmyyyydateformat>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-sm="18" fxFlex="100">
                                            <div>
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" placeholder="Grade/Percent"
                                                        formControlName="gradeOrPercent">
                                                    <mat-error
                                                        *ngIf="lang.controls['gradeOrPercent'].hasError('required') && lang.controls['gradeOrPercent'].touched">
                                                        Please enter your grade/percent.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['gradeOrPercent'].hasError('pattern') && lang.controls['gradeOrPercent'].touched">
                                                        Please enter valid grade/percent
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['gradeOrPercent'].hasError('minlength') && lang.controls['gradeOrPercent'].touched">
                                                        Please enter atleast 1 character
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['gradeOrPercent'].hasError('maxlength') && lang.controls['gradeOrPercent'].touched">
                                                        Maximum 3 characters required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="m-t-b-45">
                            <!-- <div formArrayName="academicsMastersOrPGDiploma">
                                <div *ngFor="let uni of academicsMastersOrPGDiploma.controls; let i=index" class="">
                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px" class="position_relative"
                                        style="margin-top: 20px" [formGroupName]="i">
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div>
                                                <div class="field-label">
                                                    <mat-label>{{getAcademicMasterOrDiploma(uni.value.academicsMastersOrPGDiplomaType)}}</mat-label>
                                                </div>
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" placeholder="Name"
                                                        formControlName="academicsMastersOrPGDiplomaName">
                                                    <mat-error
                                                        *ngIf="uni.controls['academicsMastersOrPGDiplomaName'].hasError('required') && uni.controls['academicsMastersOrPGDiplomaName'].touched">
                                                        Please enter the name of your degree.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="uni.controls['academicsMastersOrPGDiplomaName'].hasError('pattern') && uni.controls['academicsMastersOrPGDiplomaName'].touched">
                                                        Please enter valid name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-sm="20" fxFlex="100">
                                            <div>
                                                <div class="field-label">
                                                    <mat-label>
                                                        <span style="opacity: 0;">Institution</span>
                                                    </mat-label>
                                                </div>
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" placeholder="Institution"
                                                        formControlName="academicsMastersOrPGDiplomaInstitution">
                                                    <mat-error
                                                        *ngIf="uni.controls['academicsMastersOrPGDiplomaInstitution'].hasError('required') && uni.controls['academicsMastersOrPGDiplomaInstitution'].touched">
                                                        Please enter the name of Institution.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="uni.controls['academicsMastersOrPGDiplomaInstitution'].hasError('pattern') && uni.controls['academicsMastersOrPGDiplomaInstitution'].touched">
                                                        Please enter valid institution name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row wrap" fxFlex.gt-sm="26" fxFlex="100" fxLayoutGap="10px">
                                            <div fxFlex="100">
                                                <div style="flex-direction: column;
                                                box-sizing: border-box;
                                                display: flex;">
                                                    <div class="field-label">
                                                        <mat-label><span style="opacity: 0;">Year</span></mat-label>
                                                    </div>
                                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="5px">
                                                        <div fxFlex.gt-sm="45" fxFlex="100">
                                                            <app-mmyyyydateformat placeholder="MM/YYYY"
                                                                formControlName="academicsMastersOrPGDiplomaNameYearFrom"
                                                                requiredMessage="Please enter the start date."
                                                                [testDateMaxDate]="testDateMaxDate" [testDateMinDate]=""
                                                                [submitClicked]="submitClicked"
                                                                (optionSelected)="onSelectYearFrom($event,i,'academicsMastersOrPGDiploma','academicsMastersOrPGDiplomaNameYearFrom')">
                                                            </app-mmyyyydateformat>
                                                        </div>
                                                        <div fxFlex.gt-sm="45" fxFlex="100">
                                                            <app-mmyyyydateformat placeholder="MM/YYYY"
                                                                formControlName="academicsMastersOrPGDiplomaNameYearTo"
                                                                requiredMessage="Please enter the end date."
                                                                [testDateMaxDate]="testDateMaxDate"
                                                                [testDateMinDate]="uni.controls['academicsMastersOrPGDiplomaNameYearFrom'].value"
                                                                [submitClicked]="submitClicked"
                                                                (optionSelected)="onSelectYearFrom($event,i,'academicsMastersOrPGDiploma','academicsMastersOrPGDiplomaNameYearTo')">
                                                            </app-mmyyyydateformat>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxLayout="row wrap" fxFlex.gt-sm="26" fxFlex="100" fxLayoutGap="10px"
                                            class="position_relative">
                                            <div class="position_absolute na-button">
                                                <span>N/A</span>
                                            </div>
                                            <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="5px">
                                                <div fxFlex.gt-sm="45" fxFlex="100">
                                                    <div class="field-label">
                                                        <mat-label><span>Grade/percent</span></mat-label>
                                                    </div>
                                                    <mat-form-field style="width: 100%" appearance="outline">
                                                        <input matInput type="text" placeholder="Grade/Percent"
                                                            formControlName="academicsMastersOrPGDiplomaGradeOrPercent">
                                                        <mat-error
                                                            *ngIf="uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].hasError('required') && uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].touched">
                                                            Please enter your grade/percent.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].hasError('pattern') && uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].touched">
                                                            Please enter valid grade/percent
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].hasError('minlength') && uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].touched">
                                                            Please enter atleast 1 character
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].hasError('maxlength') && uni.controls['academicsMastersOrPGDiplomaGradeOrPercent'].touched">
                                                            Maximum 3 characters required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="position_absolute close-button masters"
                                            (click)="removeacademicsMastersOrPGDiploma(i)">
                                            <div class="close-icon">
                                                <mat-icon style="color: #A5A5A5;">cancel</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="addMoreUniversity">
                                <!-- <button type="button" mat-raised-button color="primary"
                                    (click)="addacademicsBachelors()"
                                    style="background-color: #0A6EBD;margin-right: 15px;"> + Bachelors </button>
                                <button type="button" mat-raised-button color="primary" (click)="addacademicsMasters()"
                                    style="background-color: #0A6EBD;margin-right: 15px;"> + Masters </button>
                                <button type="button" mat-raised-button color="primary"
                                    (click)="addacademicsPGDiploma()" style="background-color: #0A6EBD;"> + PG Diploma
                                </button> -->
                            </div>
                        </div>
                        <div class="m-t-b-45" fxLayout="row wrap">
                            <div fxFlex="100">
                                <div class="field-label">
                                    <mat-label>
                                        Standardised Test
                                        <br><small>Please choose the test type with your highest scores.</small>
                                    </mat-label>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="50px">
                                    <mat-checkbox class="app-content"
                                        (click)="addacademicsStandardisedTestInfo('GRE')">GRE</mat-checkbox>
                                    <mat-checkbox class="app-content"
                                        (click)="addacademicsStandardisedTestInfo('GMAT')">GMAT</mat-checkbox>
                                </div>
                            </div>
                            <div formArrayName="academicsStandardisedTestInfo">
                                <div *ngFor="let lang of academicsStandardisedTestInfo.controls; let i=index">
                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px"
                                        class="academic-type-fields" [formGroupName]="i">
                                        <div fxFlex="100"><mat-label>{{lang.value.type}} <span>*</span></mat-label></div>
                                        <div fxFlex.gt-sm="30" fxFlex="100">
                                            <div>
                                                <!-- <div class="field-label">
                                                     <mat-label>
                                                        <span style="opacity: 0;">Test Date</span>
                                                        </mat-label>
                                                </div> -->
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" formControlName="date"
                                                        placeholder="DD/MM/YYYY"
                                                        [matDatepicker]="dateOfBirthPickerAcademic"
                                                        (focus)="dateOfBirthPickerAcademic.open()"
                                                        [max]="testDateMaxDate">
                                                    <mat-datepicker-toggle matSuffix
                                                        [for]="dateOfBirthPickerAcademic"></mat-datepicker-toggle>
                                                    <mat-datepicker #dateOfBirthPickerAcademic></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="lang.controls['date'].hasError('required') && lang.controls['date'].touched">
                                                        Please enter the test date.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-sm="30" fxFlex="100">
                                            <div>
                                                <!-- <div class="field-label">
                                                    <mat-label>
                                                        <span style="opacity: 0;">Score</span>
                                                    </mat-label>
                                                </div> -->
                                                <mat-form-field style="width: 100%" appearance="outline">
                                                    <input matInput type="text" placeholder="Score"
                                                        formControlName="score">
                                                    <mat-error
                                                        *ngIf="lang.controls['score'].hasError('required') && lang.controls['score'].touched">
                                                        Score field is required.
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['score'].hasError('pattern') && lang.controls['score'].touched">
                                                        Please enter valid score
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['score'].hasError('minlength') && lang.controls['score'].touched">
                                                        Please enter atleast 1 character
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="lang.controls['score'].hasError('maxlength') && lang.controls['score'].touched">
                                                        Maximum 3 characters required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>

        <div id="buttonCard">
            <mat-card appearance="outlined" class="testing-card">
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <div class="cancel-button">
                            <button type="button" mat-stroked-button style="background-color: #ffffff;color: #000000;"
                                routerLink="/student-management/student_list">
                                Cancel </button>
                        </div>
                        <div class="save-and-next-button">
                            <button type="button" mat-stroked-button (click)="addStudent()"
                                style="background-color: #951819;color: #fff;"> Save & Next </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</div>