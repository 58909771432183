import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  catchError,
  tap
} from 'rxjs/operators';

import { LoaderService } from 'src/app/shared/services/loader.service';
import { ShowSuccessMsg, ShowErrorMessage, ShortlistedShowErrorMsg } from '../app.actions';
import {
  EStudentManagementActions,
  InviteStudent,
  SendStudentProfile,
  GetStudentList,
  GetStudentListSuccess,
  GetInviteFailedList,
  GetInviteFailedListSuccess,
  GetDashboardMetrics,
  DashboardMetrics,
  GetLeadsbyCard,
  LeadsbyCard,
  ApplyCourse,
  ApplyCourseSuccess,
  GetStudentLoginDetail,
  GetApplicationStatus,
  GetApplicationStatusSuccess,
  LockStudent,
  GetUniversityList,
  GetUniversityListV2,
  GetUniversityListSuccess,
  GetUniversityListSuccessV2,
  GetCourseList,
  GetCourseListSuccess,
  ApplyUniversityApplications,
  ApplyUniversityApplicationsFailure,
  SaveUniversityApplications,
  GetAppliedApplications,
  GetAppliedApplicationsSuccess,
  GetShortlistedApplications,
  GetShortlistedApplicationsSuccess,
  AcceptShortlistedApplications,
  GetStudentProfile,
  GetStudentProfileSuccess,
  PayNow,
  PayNowSuccess,
  UniversityProposedPaymentStatus,
  UniversityProposedPaymentStatusSuccess,
  GetDegreeList,
  GetDegreeListSuccess,
  GetExploreUniversity,
  GetExploreUniversitySuccess,
  GetSaveUniversityApplicationsSuccess,
  GetSaveUniversityApplications,
  DeleteFromInviteFailed,
  DeleteFromInviteFailedSuccess,
  UpdateDocumentCompletion,
  UpdateDocumentCompletionSuccess,
  GetStudentApplicationStatus,
  GetStudentApplicationStatusSuccess,
  GetStudentNotification,
  GetStudentNotificationSuccess,
  GetStudentSavedFavoriteUniversityList,
  GetStudentSavedFavoriteUniversityListSuccess,
  SaveStudentFavoriteUniversityList,
  CreateStudent,
  SendStudentProfileSuccess,
  CreateStudentSuccess
} from './student-management.actions';
import { StudentManagementService } from './student-management.service';
import { IUser } from '../model/user.model';
import { LoginSuccess } from '../users/users.actions';

@Injectable()
export class StudentManagementEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private studentMangementService: StudentManagementService,
    private loaderService: LoaderService,
  ) { }

  // @Effect()
  inviteStudent$ = createEffect(() => this.actions$.pipe(
    ofType<InviteStudent>(EStudentManagementActions.InviteStudent),
    switchMap(({ inviteStudent }: InviteStudent) => this.studentMangementService
      .inviteStudentFromService(inviteStudent).pipe(
        mergeMap(() => [
          new GetStudentList(inviteStudent.recruiterId, inviteStudent.roleId),
          new GetInviteFailedList(inviteStudent.recruiterId, inviteStudent.roleId, inviteStudent.recruiterSalesforceId),
          new ShowSuccessMsg('Student Invited Successfully'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  sendStudentProfile$ = createEffect(() => this.actions$.pipe(
    ofType<SendStudentProfile>(EStudentManagementActions.SendStudentProfile),
    switchMap(({ studentProfile }) => this.studentMangementService
      .sendStudentProfileFromService(studentProfile).pipe(
        mergeMap(() => [
          new ShowSuccessMsg('Saved Successfully'),
          new SendStudentProfileSuccess(studentProfile),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStudentList$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentList>(EStudentManagementActions.GetStudentList),
    switchMap(({ id, roleId }: GetStudentList) => this.studentMangementService
      .getStudentListFromService(id, roleId).pipe(
        mergeMap((response) => [
          new GetStudentListSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getInviteFailedList$ = createEffect(() => this.actions$.pipe(
    ofType<GetInviteFailedList>(EStudentManagementActions.GetInviteFailedList),
    switchMap(({ id, roleId, salesforceId }: GetInviteFailedList) => this.studentMangementService
      .getInviteFailedListFromService(id, roleId, salesforceId).pipe(
        mergeMap((response) => [
          new GetInviteFailedListSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  deleteStudentFromFailedList$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteFromInviteFailed>(EStudentManagementActions.DeleteFromInviteFailed),
    switchMap(({ element }: DeleteFromInviteFailed) => this.studentMangementService
      .deleteFromFailedListFromService(element.id).pipe(
        mergeMap((response) => [
          new DeleteFromInviteFailedSuccess(response),
          new GetInviteFailedList(element.recruiterId, element.roleId, element.roleId === 5 ? element.recruiterSalesforceId : element.staffSalesforceId),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStudentProfile$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentProfile>(EStudentManagementActions.GetStudentProfile),
    switchMap(({ contactId }) => this.studentMangementService
      .getStudentProfileFormFromService(contactId).pipe(
        mergeMap((studentProfile: any) => [
          new GetStudentProfileSuccess(studentProfile),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getExploreUniversity$ = createEffect(() => this.actions$.pipe(
    ofType<GetExploreUniversity>(EStudentManagementActions.GetExploreUniversity),
    switchMap(({ contactId }) => this.studentMangementService
      .getExploreUniversityFromService(contactId).pipe(
        mergeMap((exploreUniversity: any) => [
          new GetExploreUniversitySuccess(exploreUniversity),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getDashboardMetrics$ = createEffect(() => this.actions$.pipe(
    ofType<GetDashboardMetrics>(EStudentManagementActions.GetDashboardMetrics),
    switchMap(({ id, roleId }: GetDashboardMetrics) => this.studentMangementService
      .getDashboardMetricsFromService(id, roleId).pipe(
        mergeMap((response) => [
          new DashboardMetrics(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  leadsbyCard$ = createEffect(() => this.actions$.pipe(
    ofType<GetLeadsbyCard>(EStudentManagementActions.GetLeadsbyCard),
    switchMap(({ id, roleId, cardType }: GetLeadsbyCard) => this.studentMangementService
      .getDashboardMetricsFromServiceLeads(id, roleId, cardType).pipe(
        mergeMap((response) => [
          new LeadsbyCard(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  applyCourse$ = createEffect(() => this.actions$.pipe(
    ofType<ApplyCourse>(EStudentManagementActions.ApplyCourse),
    switchMap(({ formCode }) => this.studentMangementService
      .applyCourseFromService(formCode).pipe(
        mergeMap((response) => [
          new ApplyCourseSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getApplicationStatus$ = createEffect(() => this.actions$.pipe(
    ofType<GetApplicationStatus>(EStudentManagementActions.GetApplicationStatus),
    switchMap(() => this.studentMangementService
      .getApplicationStatusFormFromService().pipe(
        mergeMap((response) => [
          new GetApplicationStatusSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStudentLoginDetail$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentLoginDetail>(EStudentManagementActions.GetStudentLoginDetail),
    switchMap(({ contactId }: GetStudentLoginDetail) => this.studentMangementService
      .getStudentLoginDetail(contactId).pipe(
        mergeMap((user: IUser) => [
          new LoginSuccess(user.role, user),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  lockStudent$ = createEffect(() => this.actions$.pipe(
    ofType<LockStudent>(EStudentManagementActions.LockStudent),
    switchMap(({ lockStudent }) => this.studentMangementService
      .lockStudentFromService(lockStudent).pipe(
        mergeMap((result: any) => [
          new GetStudentList(lockStudent.recruiterId, lockStudent.roleId),
          new ShowSuccessMsg((result.isLock ? 'Student Locked' : 'Student Unlocked')),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getDegeeList$ = createEffect(() => this.actions$.pipe(
    ofType<GetDegreeList>(EStudentManagementActions.GetDegreeList),
    switchMap(({ country, salesforceId }) => this.studentMangementService
      .getDegreeListFromService(country, salesforceId).pipe(
        mergeMap((result) => [
          new GetDegreeListSuccess(result),
          new ShortlistedShowErrorMsg(false)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
          new ShortlistedShowErrorMsg(true)
        ))
      ))
  ));

  // @Effect()
  getUniversityList$ = createEffect(() => this.actions$.pipe(
    ofType<GetUniversityList>(EStudentManagementActions.GetUniversityList),
    switchMap(({
      country, degree, courseName, salesforceId
    }) => this.studentMangementService
      .getUniversityListFromService(country, degree, courseName, salesforceId).pipe(
        mergeMap((result) => [
          new GetUniversityListSuccess(result),
          new ShortlistedShowErrorMsg(false)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
          new ShortlistedShowErrorMsg(true)
        ))
      ))
  ));

  // @Effect()
  getUniversityListV2$ = createEffect(() => this.actions$.pipe(
    ofType<GetUniversityListV2>(EStudentManagementActions.GetUniversityListV2),
    switchMap(() => this.studentMangementService
      .getUniversityListFromCRM().pipe(
        mergeMap((result) => [
          new GetUniversityListSuccessV2(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getCourses$ = createEffect(() => this.actions$.pipe(
    ofType<GetCourseList>(EStudentManagementActions.GetCourseList),
    switchMap(({ country, degree, salesforceId }) => this.studentMangementService
      .getCoursesFromService(country, degree, salesforceId).pipe(
        mergeMap((result) => [
          new GetCourseListSuccess(result),
          new ShortlistedShowErrorMsg(false)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
          new ShortlistedShowErrorMsg(true)
        ))
      ))
  ));

  // @Effect()
  applyUniversityApplications$ = createEffect(() => this.actions$.pipe(
    ofType<ApplyUniversityApplications>(EStudentManagementActions.ApplyUniversityApplications),
    switchMap(({ applyUniversityApplications }) => this.studentMangementService
      .applyUniversityApplicationsFromService(applyUniversityApplications).pipe(
        mergeMap(() => [
          // new GetAppliedApplications(applyUniversityApplications.contactId),
          new GetShortlistedApplications(applyUniversityApplications.contactId),
          // new ShowSuccessMsg('Applied Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
          new ApplyUniversityApplicationsFailure(error),
        ))
      ))
  ));

  // @Effect()
  saveUniversityApplications$ = createEffect(() => this.actions$.pipe(
    ofType<SaveUniversityApplications>(EStudentManagementActions.SaveUniversityApplications),
    switchMap(({ saveUniversityApplications }) => this.studentMangementService
      .saveUniversityApplicationsFromService(saveUniversityApplications).pipe(
        mergeMap(() => [
          new GetSaveUniversityApplications(saveUniversityApplications.contactId),
          new ShowSuccessMsg('Saved Successfully'),

        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getAppliedApplications$ = createEffect(() => this.actions$.pipe(
    ofType<GetAppliedApplications>(EStudentManagementActions.GetAppliedApplications),
    switchMap(({ contactId }) => this.studentMangementService
      .getAppliedApplicationsFromService(contactId).pipe(
        mergeMap((result) => [
          new GetAppliedApplicationsSuccess(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getShortlistedApplications$ = createEffect(() => this.actions$.pipe(
    ofType<GetShortlistedApplications>(EStudentManagementActions.GetShortlistedApplications),
    switchMap(({ contactId }) => this.studentMangementService
      .getShortlistedApplicationsFromService(contactId).pipe(
        mergeMap((result) => [
          new GetShortlistedApplicationsSuccess(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  acceptShortlistedApplications$ = createEffect(() => this.actions$.pipe(
    ofType<AcceptShortlistedApplications>(EStudentManagementActions.AcceptShortlistedApplications),
    switchMap(({ acceptShortlistedApplications }) => this.studentMangementService
      .acceptShortlistedApplicationsFromService(acceptShortlistedApplications).pipe(
        tap(() => {
          if (!localStorage.getItem('recruiter-student-user')) {
            this.router.navigateByUrl('/payment-student');
          }
        }),
        mergeMap(() => [
          new GetAppliedApplications(acceptShortlistedApplications.contactId),
          new GetShortlistedApplications(acceptShortlistedApplications.contactId),
          new UniversityProposedPaymentStatus(acceptShortlistedApplications.contactId),
          new ShowSuccessMsg('Accepted Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  payNow$ = createEffect(() => this.actions$.pipe(
    ofType<PayNow>(EStudentManagementActions.PayNow),
    switchMap(({ paymentData }) => this.studentMangementService
      .payNowFromService(paymentData).pipe(
        mergeMap((result) => [
          new PayNowSuccess(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  checkProposedPaidStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UniversityProposedPaymentStatus>(EStudentManagementActions.UniversityProposedPaymentStatus),
    switchMap(({ contactId }) => this.studentMangementService
      .checkProposedPaymentStatus(contactId).pipe(
        mergeMap((result) => [
          new UniversityProposedPaymentStatusSuccess(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getSaveUniversityApplications$ = createEffect(() => this.actions$.pipe(
    ofType<GetSaveUniversityApplications>(EStudentManagementActions.GetSaveUniversityApplications),
    switchMap(({ contactId }) => this.studentMangementService
      .getSaveUniversityApplications(contactId).pipe(
        mergeMap((result) => [
          new GetSaveUniversityApplicationsSuccess(result),

          // new ShowSuccessMsg('Saved Successfully')

        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStudentApplicationStatus$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentApplicationStatus>(EStudentManagementActions.GetStudentApplicationStatus),
    switchMap(({ contactId }) => this.studentMangementService
      .getStudentApplicationStatusFromService(contactId).pipe(
        mergeMap((result) => [
          new GetStudentApplicationStatusSuccess(result),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  updateDocumentCompletion$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDocumentCompletion>(EStudentManagementActions.UpdateDocumentCompletion),
    switchMap(({ documentCompletion }) => this.studentMangementService
      .updateDocumentCompletionFromService(documentCompletion).pipe(
        mergeMap((result) => [
          new UpdateDocumentCompletionSuccess(result)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  studentNotification$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentNotification>(EStudentManagementActions.GetStudentNotification),
    switchMap(() => this.studentMangementService
      .getStudentNotificationFromService().pipe(
        mergeMap((result) => [
          new GetStudentNotificationSuccess(result)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStudentSavedFavoriteUniversityList$ = createEffect(() => this.actions$.pipe(
    ofType<GetStudentSavedFavoriteUniversityList>(EStudentManagementActions.GetStudentSavedFavoriteUniversityList),
    switchMap(({ contactId }) => this.studentMangementService
      .getStudentSavedFavoriteUniversityList(contactId).pipe(
        mergeMap((favoriteUniversity: any) => [
          new GetStudentSavedFavoriteUniversityListSuccess(favoriteUniversity)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  saveStudentFavoriteUniversityList$ = createEffect(() => this.actions$.pipe(
    ofType<SaveStudentFavoriteUniversityList>(EStudentManagementActions.SaveStudentFavoriteUniversityList),
    switchMap(({ studentFavoriteUniversity }) => this.studentMangementService
      .saveStudentSavedFavoriteUniversityList(studentFavoriteUniversity).pipe(
        mergeMap(() => [
          new GetStudentSavedFavoriteUniversityList(studentFavoriteUniversity.contactId),
          new ShowSuccessMsg(studentFavoriteUniversity.isFavorite ? 'Saved as favorite' : 'Removed from favorite')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  createStudent$ = createEffect(() => this.actions$.pipe(
    ofType<CreateStudent>(EStudentManagementActions.CreateStudent),
    switchMap(({ createStudent }: CreateStudent) => this.studentMangementService
      .createStudentFromService(createStudent).pipe(
        mergeMap((studentInfo: any) => [
          new CreateStudentSuccess(studentInfo),
          // new GetStudentList(createStudent.recruiterId, createStudent.roleId),
          new ShowSuccessMsg('Student Created Successfully'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));
}
