import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { INotice, INoticeIcon, noticeIcon } from '../../models/shared.model';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
  })
export class NoticeComponent {
  public header: string;
  public message: string;
  public status: INoticeIcon;

  constructor(
    private dialogRef: MatDialogRef<NoticeComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: INotice
  ) {
    this.header = this.dialogData.header;
    this.message = this.dialogData.message;
    this.status = noticeIcon[this.dialogData.type];
  }
}
