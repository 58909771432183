<h2 mat-dialog-title>Notification Settings</h2>
<section class="example-section">
  <mat-checkbox class="example-margin" [(ngModel)]="sound"> Sound On/Off</mat-checkbox>

  <div *ngIf="sound">
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
      [(ngModel)]="selectedSound">
      <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-xs="50" *ngFor="let i of soundList">
          <mat-radio-button class="example-radio-button" [value]="i.id" (click)="playAudio(i)">
            {{"sound "+i.id}}
          </mat-radio-button>
        </div>

      </div>
    </mat-radio-group>
  </div>

</section>
<section>
  <mat-checkbox class="example-margin" [(ngModel)]="showNotifications" (change)="subscription()">Show Notifications</mat-checkbox>
</section>