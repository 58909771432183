export enum EStudentMangementEndPoints {
  INVITE_STUDENT = 'session/invitestudent',
  STUDENT_PROFILE = 'student/form',
  STUDENT_LIST = 'recruiter/getStudentData',
  INVITE_FAILED_LIST = 'recruiter/getInviteFailed',
  DELETE_FROM_FAILED_LIST ='recruiter/deleteFromFailedList',
  DASHBOARD_METRICS = 'recruiter/dashboardMetrics',
  CARD_METRICS = 'recruiter/getLeadsByCards',
  APPLY_COURSE = 'student/formurl',
  PROFILE_PROGRESS = 'student/getProfilePercentage',
  GET_STUDENT_LOGIN_DATA = 'student/getStudentLoginDetail',
  GET_STUDENT_NOTIFICATION = 'notification/getStudentNotification',
  SWITCH_NOTIFICATION = 'notification/switchStudentNotification',
  SEND_STUDENT_REUPLOAD_NOTIFICATION = 'notification/sendStudentReuploadNotification',
  SEND_STUDENT_NOTIFICATION = 'notification/sendStudentNotificationEmail',
  GET_STUDENT_PROFILE = 'student/studentprofileformdetails',
  GET_APPLICATION_STATUS = 'student/getApplicationStatus',
  LOCK_STUDENT = 'recruiter/lockStudent',
  GET_DEGREE_LIST = 'student/getDegreeList',
  GET_UNIVERSITY_LIST = 'student/getUniversityList',
  GET_UNIVERSITY_LISTV2 = 'universitryhub/listOfUniversities',
  GET_COURSES = 'student/getCourses',
  APPLY_UNIVERSITY_APPLICATIONS = 'student/applyUniversityApplications',
  GET_APPLIED_APPLICATIONS = 'student/getAppliedApplications',
  GET_SHORTLISTED_APPLICATIONS = 'student/getShortlistedApplications',
  ACCEPT_SHORTLISTED_APPLICATIONS = 'student/acceptShortlistedApplications',
  PAY_NOW = 'student/payNow',
  CHECK_PROPOSED_PAYMENT_STATUS = 'student/checkProposedPaymentStatus',
  GET_EXPLORE_UNIVERSITY = 'student/getExploreUniversity',
  SAVE_UNIVERSITY_APPLICATIONS = 'student/saveUniversityApplications',
  GET_SAVE_UNIVERSITY_APPLICATION = 'student/getSavedUniversityApplications',
  UPDATE_STUDENT_NOTIFICATION = 'notification/updateStudentNotification',
  SAVE_STUDENT_NOTIFICATION = 'notification/saveStudentNotification',
  UPDATE_DOCUMENT_COMPLETETION = 'student/updateDocumentCompletion',
  GET_STUDENT_APPLICATION_STATUS = 'student/getStudentApplicationStatus',
  GET_STUDENT_FAVORITE_UNIVERSITY = 'student/getStudentFavoriteUniversity',
  SAVE_STUDENT_FAVORITE_UNIVERSITY = 'student/saveStudentFavoriteUniversity',
  CREATE_STUDENT = 'recruiter/createStudent',
}

export interface IDashboardMetrics {
  status: string;
  count: string;
}

export const dashboardMetricsModal = {
  universitiesProposed: '0',
  invited: '0',
  registered: '0',
  inviteRegistered: '0',
  nonInviteRregistered: '0',
  applicationSubmitted: '0',
  profileCompleted: '0',
  inviteProfileCompleted: '0',
  nonInviteProfileCompleted: '0',
  inviteUniversitiesProposed: '0',
  nonInviteUniversitiesProposed: '0',
  inviteApplicationSubmitted: '0',
  nonInviteApplicationSubmitted: '0',
  enrolled: '0',
  applicationSubmittedcrm: '0',
  totalAdded: '0',
  transfer: '0',
  international: '0'
};

export interface IApplyCourseSuccess {
  applicationUrl: string;
}

export interface IApplicationStatus {
  formCode: string;
  applicationStatus: string;
  paymentStatus: string;
  applicationProgress?: string;
}

export interface ISendStudentNotification {
  emailId: string;
  university: string;
  message?: string;
  subjectId?: number;
}

export interface ISwitchNotification {
  studentId: number;
  enableNotification: boolean;
}

export interface IUniversities {
  universityId: number;
  universityName: string;
}

export interface ICourses {
  courseId: string | number;
  courseName: string;
}

export interface IApplyUniversityApplications {
  id?: string;
  contactId: string;
  deleteUniversity?: any;
  isDraft?: boolean;
  applications: {
    order: number;
    universityId: string;
    universityName: string;
    courseId: string;
    courseName: string;
    country: string;
    degree: string;
  }[]
}

export interface IApplications {
  order: number,
  universityId: string;
  universityName: string;
  courseId: string;
  courseName: string;
  country: string;
  degree: string;
  fee?: number;
  accepted?: boolean;
  paid?: boolean;
}

export interface IShortlistedApplications {
  length: number;
  totalFee:number;
  accepted: boolean;
  applications: IApplications[];
}

export interface IPayNowLink {
  status: string;
  paymentLink: string;
  paid: boolean;
}

export interface IProposedPaymentStatus {
  accepted: boolean;
  paid: boolean;
}
