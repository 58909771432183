import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { UserService } from 'src/app/store/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.userService.getLoggedInUser;
    localStorage.removeItem('isSeperateExploreUniversities');
    if (user) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    } 
    
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
    if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'STUDENT')) {
      this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
    } else if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'RECRUITER')) {
      this.router.navigate(['authentication/recruiter/login'], { queryParams: { returnUrl: state.url } });
    }
    return false;
  }
}
