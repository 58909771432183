import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap, mergeMap, catchError, tap
} from 'rxjs/operators';

import { ShowSuccessMsg, ShowErrorMessage } from '../app.actions';
import { IRecruiterRequest, IRecruiterSession } from '../model/recruiter.model';
import { RecruiterService } from './recruiter.service';
import {
  ERecruiterActions,
  AddRecruiterSuccess,
  AddRecruiter,
  AddRecruiterFailure,
  LoginRecruiter,
  LoginRecruiterSuccess,
  LoginRecruiterFailure,
  RecruiterProfile,
  EditRecruiterFreelancerProfile,
  AddRecruiterOrganization,
  InviteRecruiter,
  GetRecruiterList,
  GetRecruiterListSuccess,
  RegisterInvitedRecruiterFreelancer,
  ChangeRecruiterStatus,
  InviteStaff,
  GetStaffList,
  GetStaffListSuccess,
  ChangeStaffStatus,
  DeleteStaff,
  UpdateStaffProfile,
  EditRecruiterOrganizationProfile,
  SaveBankingInformation,
  GetBankingInformation,
  GetBankingInformationSuccess,
  GetCommissionPolicy,
  GetCommissionPolicySuccess,
  GetRecruiterNotification,
  GetRecruiterNotificationSuccess,
  GetRecruiterAssociateNotification,
  GetRecruiterAssociateNotificationSuccess,
  ReAssignStudent,
  GetRecruiterStudentShorlistedApplication,
  GetRecruiterStudentShorlistedApplicationSuccess,
  ChangeStudentCategory,
  GetAllSound,
  GetAllSoundSuccess,
  GetSoundById,
  GetSoundByIdSuccess,
  UpdateSound,
  NotificationSubscibe,
} from './recruiter.actions';
import { resolvePostLoginNavigation } from '../users/users.effects';
import { LoggedInUser } from '../users/users.actions';
import { GetStudentList } from '../student-management/student-management.actions';

const resolvePostRegistrationNavigation = (user, router) => {
  router.navigateByUrl('/authentication/recruiter/login');
  // router.navigateByUrl('/recruiter');
};

// const routeToRecruiterDashboard = (router) => {
//   router.navigateByUrl('/dashboards/dashboard3');
// };

const routeToRecruiterProfile = (router) => {
  router.navigateByUrl('/recruiter-profile');
};

const routeToBankingInformation = (router) => {
  router.navigateByUrl('/banking-information');
};

@Injectable()
export class RecruiterEffects {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private recruiterService: RecruiterService,
  ) { }

  // @Effect()
  addRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<AddRecruiter>(ERecruiterActions.AddRecruiter),
    switchMap(({ recruiterProfile }: AddRecruiter) => this.recruiterService.addRecruiter(recruiterProfile).pipe(
      tap(user => resolvePostRegistrationNavigation(user, this.router)),
      mergeMap((user: IRecruiterRequest) => [
        new ShowSuccessMsg('You have successfully registered'),
        new AddRecruiterSuccess(user),
      ]),
      catchError(error => of(
        new ShowErrorMessage(error),
        new AddRecruiterFailure(error),
      ))
    ))
  ));

  // @Effect()
  addRecruiterOrganization$ = createEffect(() => this.actions$.pipe(
    ofType<AddRecruiterOrganization>(ERecruiterActions.AddRecruiterOrganization),
    switchMap(({ recruiterProfile }: AddRecruiterOrganization) => this.recruiterService.addRecruiterOrganization(recruiterProfile).pipe(
      tap(user => resolvePostRegistrationNavigation(user, this.router)),
      mergeMap((user: IRecruiterRequest) => [
        new ShowSuccessMsg('You have successfully registered'),
        new AddRecruiterSuccess(user),
      ]),
      catchError(error => of(
        new ShowErrorMessage(error),
        new AddRecruiterFailure(error),
      ))
    ))
  ));

  // @Effect()
  registerInvitedRecruiterFreelancer$ = createEffect(() => this.actions$.pipe(
    ofType<RegisterInvitedRecruiterFreelancer>(ERecruiterActions.RegisterInvitedRecruiterFreelancer),
    switchMap(({ recruiterProfile }: RegisterInvitedRecruiterFreelancer) => this.recruiterService
      .registerInvitedRecruiterFreelancer(recruiterProfile).pipe(
        tap(user => resolvePostRegistrationNavigation(user, this.router)),
        mergeMap(() => [
          new ShowSuccessMsg('You have successfully registered'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  loginRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<LoginRecruiter>(ERecruiterActions.LoginRecruiter),
    switchMap(({ payload }: LoginRecruiter) => this.recruiterService
      .loginRecruiterFromService(payload).pipe(
        tap(recruiter => resolvePostLoginNavigation(recruiter, this.router, this.route.snapshot.queryParams.returnUrl)),
        mergeMap((recruiter: IRecruiterSession) => [
          new LoggedInUser(recruiter),
          new LoginRecruiterSuccess(recruiter, recruiter.role),
          new ShowSuccessMsg('Login Successful'),
        ]),
        catchError(error => of(
          new LoginRecruiterFailure(error),
          // new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  recruiterProfile$ = createEffect(() => this.actions$.pipe(
    ofType<RecruiterProfile>(ERecruiterActions.RecruiterProfile),
    switchMap(({ id }: RecruiterProfile) => this.recruiterService
      .getRecruiterProfileFromService(id).pipe(
        mergeMap((recruiter: IRecruiterRequest) => [
          new AddRecruiterSuccess(recruiter),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  editRecruiterFreelancerProfile$ = createEffect(() => this.actions$.pipe(
    ofType<EditRecruiterFreelancerProfile>(ERecruiterActions.EditRecruiterFreelancerProfile),
    switchMap(({ recruiterProfile }: EditRecruiterFreelancerProfile) => this.recruiterService
      .editRecruiterFreelancerProfileFromService(recruiterProfile).pipe(
        tap(() => routeToRecruiterProfile(this.router)),
        mergeMap(() => [
          new ShowSuccessMsg('Profile Updated Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  editRecruiterOrganizationProfile$ = createEffect(() => this.actions$.pipe(
    ofType<EditRecruiterOrganizationProfile>(ERecruiterActions.EditRecruiterOrganizationProfile),
    switchMap(({ recruiterProfile }: EditRecruiterOrganizationProfile) => this.recruiterService
      .editRecruiterOrganizationProfileFromService(recruiterProfile).pipe(
        tap(() => routeToRecruiterProfile(this.router)),
        mergeMap(() => [
          new ShowSuccessMsg('Profile Updated Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  inviteRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<InviteRecruiter>(ERecruiterActions.InviteRecruiter),
    switchMap(({ inviteRecruiter }: InviteRecruiter) => this.recruiterService
      .inviteRecruiterFromService(inviteRecruiter).pipe(
        mergeMap(() => [
          new GetRecruiterList(inviteRecruiter.recruiterId),
          new ShowSuccessMsg('Recruiter Invited Successfully'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getRecruiterList$ = createEffect(() => this.actions$.pipe(
    ofType<GetRecruiterList>(ERecruiterActions.GetRecruiterList),
    switchMap(({ id }: GetRecruiterList) => this.recruiterService
      .getRecruiterListFromService(id).pipe(
        mergeMap((response) => [
          new GetRecruiterListSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  changeRecruiterStatus$ = createEffect(() => this.actions$.pipe(
    ofType<ChangeRecruiterStatus>(ERecruiterActions.ChangeRecruiterStatus),
    switchMap(({ changeStatus }: ChangeRecruiterStatus) => this.recruiterService
      .changeRecruiterStatusFromService(changeStatus).pipe(
        mergeMap(() => [
          new ShowSuccessMsg('Status changed successfully'),
          new GetRecruiterList(changeStatus.recruiterMasterId),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  inviteStaff$ = createEffect(() => this.actions$.pipe(
    ofType<InviteStaff>(ERecruiterActions.InviteStaff),
    switchMap(({ inviteStaff }: InviteStaff) => this.recruiterService
      .inviteStaffFromService(inviteStaff).pipe(
        mergeMap(() => [
          new GetStaffList(inviteStaff.recruiterId, inviteStaff.recruiterSalesforceId),
          new ShowSuccessMsg('Staff Invited Successfully'),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getStaffList$ = createEffect(() => this.actions$.pipe(
    ofType<GetStaffList>(ERecruiterActions.GetStaffList),
    switchMap(({ recruiterId, recruiterSalesforceId }: GetStaffList) => this.recruiterService
      .getStaffListFromService(recruiterId, recruiterSalesforceId).pipe(
        mergeMap((response) => [
          new GetStaffListSuccess(response),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  changeStaffStatus$ = createEffect(() => this.actions$.pipe(
    ofType<ChangeStaffStatus>(ERecruiterActions.ChangeStaffStatus),
    switchMap(({ changeStatus }: ChangeStaffStatus) => this.recruiterService
      .changeStaffStatusFromService(changeStatus).pipe(
        mergeMap((response) => [
          new ShowSuccessMsg((response.result ? 'Activated Successfully' : 'Deactivated Successfully')),
          new GetStaffList(changeStatus.recruiterId, changeStatus.recruiterSalesforceId),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  deleteStaff$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteStaff>(ERecruiterActions.DeleteStaff),
    switchMap(({ deleteStaff }: DeleteStaff) => this.recruiterService
      .deleteStaffFromService(deleteStaff).pipe(
        mergeMap((response: any) => [
          new ShowSuccessMsg(response.message),
          new GetStaffList(deleteStaff.recruiterId, deleteStaff.recruiterSalesforceId),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  updateStaffProfile$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateStaffProfile>(ERecruiterActions.UpdateStaffProfile),
    switchMap(({ updateStaff }: UpdateStaffProfile) => this.recruiterService
      .updateStaffProfileFromService(updateStaff).pipe(
        mergeMap((response: any) => [
          new ShowSuccessMsg(response.message),
          new GetStaffList(updateStaff.recruiterId, updateStaff.recruiterSalesforceId),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  saveBankingInformation$ = createEffect(() => this.actions$.pipe(
    ofType<SaveBankingInformation>(ERecruiterActions.SaveBankingInformation),
    switchMap(({ bankingInformation }: SaveBankingInformation) => this.recruiterService
      .saveBankingInformationFromService(bankingInformation).pipe(
        tap(() => routeToBankingInformation(this.router)),
        mergeMap((response: any) => [
          new GetBankingInformation(),
          new ShowSuccessMsg(response.message),
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getBankingInformation$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankingInformation>(ERecruiterActions.GetBankingInformation),
    switchMap(() => this.recruiterService
      .getBankingInformationFromService().pipe(
        mergeMap((response) => [
          new GetBankingInformationSuccess(response)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getCommissionPolicy$ = createEffect(() => this.actions$.pipe(
    ofType<GetCommissionPolicy>(ERecruiterActions.GetCommissionPolicy),
    switchMap(() => this.recruiterService
      .getCommissionPolicyFromService().pipe(
        mergeMap((response) => [
          new GetCommissionPolicySuccess(response)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  recruiterNotification$ = createEffect(() => this.actions$.pipe(
    ofType<GetRecruiterNotification>(ERecruiterActions.GetRecruiterNotification),
    switchMap(() => this.recruiterService
      .getNotificationFromService().pipe(
        mergeMap((result) => [
          new GetRecruiterNotificationSuccess(result)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  recruiterAssociateNotification$ = createEffect(() => this.actions$.pipe(
    ofType<GetRecruiterAssociateNotification>(ERecruiterActions.GetRecruiterAssociateNotification),
    switchMap(() => this.recruiterService
      .getAssociateNotificationFromService().pipe(
        mergeMap((result) => [
          new GetRecruiterAssociateNotificationSuccess(result)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  reAssignStudent$ = createEffect(() => this.actions$.pipe(
    ofType<ReAssignStudent>(ERecruiterActions.ReAssignStudent),
    switchMap(({ ReAssignStudentList }) => this.recruiterService
      .reAssignStudentFromService(ReAssignStudentList).pipe(
        mergeMap(() => [
          new ShowSuccessMsg('Re Assigned Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getRecruiterStudentShorlistedApplication$ = createEffect(() => this.actions$.pipe(
    ofType<GetRecruiterStudentShorlistedApplication>(ERecruiterActions.getRecruiterStudentShorlistedApplication),
    switchMap(({ recruiterSalesforceId }) => this.recruiterService
      .getShortlistedApplicationsFromService(recruiterSalesforceId).pipe(
        mergeMap((result) => [
          new GetRecruiterStudentShorlistedApplicationSuccess(result)
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  changeStudentCategory$ = createEffect(() => this.actions$.pipe(
    ofType<ChangeStudentCategory>(ERecruiterActions.ChangeStudentCategory),
    switchMap(({ changeStudentCategoryLists }) => this.recruiterService
      .changeStudentCategoryListsFromService(changeStudentCategoryLists).pipe(
        mergeMap(() => [
          new GetStudentList(changeStudentCategoryLists.recruiterId, changeStudentCategoryLists.roleId),
          new ShowSuccessMsg('Category changed Successfully')
        ]),
        catchError(error => of(
          new ShowErrorMessage(error),
        ))
      ))
  ));

  // @Effect()
  getAllSound$ = createEffect(() => this.actions$.pipe(
    ofType<GetAllSound>(ERecruiterActions.GetAllSound),
    switchMap(() => this.recruiterService.getAllSound().pipe(
      mergeMap((response) => [
        new GetAllSoundSuccess(response)
      ]),
      catchError(error => of(
        new ShowErrorMessage(error)
      ))
    ))
  ));

  // @Effect()
  getSoundById$ = createEffect(() => this.actions$.pipe(
    ofType<GetSoundById>(ERecruiterActions.GetSoundById),
    switchMap((data) => this.recruiterService.getSoundById(data).pipe(
      mergeMap((response) => [
        new GetSoundByIdSuccess(response)
      ]),
      catchError(error => of(
        new ShowErrorMessage(error)
      ))
    ))
  ));

  updateSound$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateSound>(ERecruiterActions.UpdateSound),
    switchMap(({ data }) => this.recruiterService.updateSound(data).pipe(
      mergeMap(() => [
        // new ShowSuccessMsg('')
      ]),
      catchError(error => of(
        new ShowErrorMessage(error)
      ))
    ))
  ));

  notificationSubscribe = createEffect(() => this.actions$.pipe(
    ofType<NotificationSubscibe>(ERecruiterActions.NotificationSubscibe),
    switchMap(({ data }) => this.recruiterService.notificationSubscribe(data).pipe(
      mergeMap(() => [
      ]),
      catchError(error => of(
        new ShowErrorMessage(error)
      ))
    ))
  ));
}
