import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AuthenticationService } from '../../pages/authentication/authentication.service';
import { IRecruiterSession } from '../../store/model/recruiter.model';
import { ERole } from '../../store/model/user.model';
import { GetStaffList } from '../../store/recruiter/recruiter.actions';
import { recruiterQuery } from '../../store/recruiter/recruiter.selectors';
import { SharedService } from '../services/shared.service';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface IMenu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const STUDENT_MENUITEMS_HUB = [
  {
    state: 'student-dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'student-profile',
    name: 'Profile',
    type: 'link',
    icon: 'account_box'
  },
  {
    state: 'documents',
    name: 'Documents Upload',
    type: 'link',
    icon: 'description'
  },
  {
    state: 'explore-universities',
    name: 'Explore Institute',
    type: 'link',
    icon: 'search'
  },
  {
    state: 'universities',
    name: 'Shortlisted Universities',
    type: 'link',
    icon: 'school'
  },
  /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Client didn't want this
      ****** OLD CODE:
        {
          state: 'payment-student',
          name: 'Payment',
          type: 'link',
          icon: 'payment'
        },
        {
          state: 'application-status',
          name: 'Application Status',
          type: 'link',
          icon: 'school'
        },
        {
          state: 'mobile',
          name: 'Mobile and Banking',
          type: 'link',
          icon: 'account_balance'
        },
        ************************************************************************
  */
  // {
  //     state: 'academics',
  //     name: 'Academics',
  //     type: 'link',
  //     icon: 'source',
  // children: [
  //     // { state: 'dashboard1', name: 'Academic Progress', type: 'link' },
  //     { state: 'dashboard2', name: 'Admission Progress', type: 'link' }
  // ]
  // },
  // {
  //     state: 'listofunivs',
  //     name: 'List Of Univs',
  //     type: 'link',
  //     icon: 'insert_drive_file'
  // },
  // {
  //     state: 'financial',
  //     name: 'Financial Account',
  //     type: 'link',
  //     icon: 'monetization_on'
  // },
  // {
  //     state: 'financial_aid',
  //     name: 'Financial Aid',
  //     type: 'link',
  //     icon: 'account_balance_wallet'
  // },
  // {
  //     state: 'manage_classes',
  //     name: 'Manage Classes',
  //     type: 'link',
  //     icon: 'article'
  // },
  // {
  //   state: 'tasks',
  //   name: 'Tasks',
  //   type: 'link',
  //   icon: 'assignment'
  // },

  // {
  //     state: 'login_history',
  //     name: 'Login History',
  //     type: 'link',
  //     icon: 'bookmark'
  // },{
  //     state: 'my_academics',
  //     name: 'My Academics',
  //     type: 'link',
  //     icon: 'book'
  // },{
  //     state: 'research_tracking',
  //     name: 'Research Tracking',
  //     type: 'link',
  //     icon: 'description'
  // },
  // {
  //     state: 'award_profiles',
  //     name: 'Award Profile',
  //     type: 'link',
  //     icon: 'fact_check'
  // },
  // {
  //     state: 'grade_illness_notice',
  //     name: 'Grade/Illness Notice',
  //     type: 'link',
  //     icon: 'receipt'
  // }
];

const STUDENT_MENUITEMS_NOT_HUB = [
  {
    state: 'student-dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'application',
    name: 'Application',
    type: 'link',
    icon: 'school'
  },
  {
    state: 'documents',
    name: 'Documents Upload',
    type: 'link',
    icon: 'description'
  },
  {
    state: 'mobile',
    name: 'Mobile and Banking',
    type: 'link',
    icon: 'school'
  },
];

const RECRUITER_FREELANCER_MENUITEMS = [
  {
    state: 'dashboards/dashboard3',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  // {
  //   state: 'student-management',
  //   name: 'Student Management',
  //   type: 'link',
  //   icon: 'person',
  // },
  {
    state: 'recruiter-explore-universities',
    name: 'Explore Institute',
    type: 'link',
    icon: 'search'
  },
  {
    state: 'student-management/student_list',
    name: 'Student Management',
    type: 'link',
    icon: 'person',
    children: []
    // children: [
    //   { state: 'student-management/student_list', name: 'Students List', type: 'link' },
    // { state: 'student-management/student_profile', name: 'Create Student Profile', type: 'link' },
    // { state: 'student-management/student_document', name: 'Student Document', type: 'link' },
    // { state: 'student-management/student_explore_institutes', name: 'Shortlist Institutes', type: 'link' },
    // { state: 'student-management/student_shortlisted_universities', name: 'Shortlisted Universities', type: 'link' },
    /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Client didn't want this
      ****** OLD CODE:
      { state: 'student-management/student_payment', name: 'Payment', type: 'link' },
      { state: 'student-management/student_application_status', name: 'Application Status', type: 'link' }
      *********************************************************************
      */
    // ]
  },
  {
    state: 'recruiter-profile',
    name: 'Recruiter Details',
    type: 'link',
    icon: 'account_circle'
  },
  // {
  //   state: 'manage-staff',
  //   name: 'Manage Staff',
  //   type: 'link',
  //   icon: 'groups',
  // },
  {
    state: 'commission-policy',
    name: 'Commission Policy',
    type: 'link',
    icon: 'policy',
  },
  // {
  //   state: 'banking-information',
  //   name: 'Banking Information',
  //   type: 'link',
  //   icon: 'account_balance',
  // },
  // {
  //   state: 'mobile',
  //   name: 'Mobile and Banking',
  //   type: 'link',
  //   icon: 'school'
  // }
  // {
  //     state: 'tasks',
  //     name: 'Tasks',
  //     type: 'link',
  //     icon: 'assignment'
  // },
  // {
  //     state: 'application',
  //     name: 'Application',
  //     type: 'link',
  //     icon: 'school'
  // }
];

const RECRUITER_ORGANIZATION_MENUITEMS = [
  {
    state: 'dashboards/dashboard3',
    name: 'Dashboard',
    type: 'sub',
    icon: 'dashboard',
    children: []
  },
  {
    state: 'recruiter-explore-universities',
    name: 'Explore Institute',
    type: 'link',
    icon: 'search'
  },
  {
    state: 'student-management/student_list',
    name: 'Student Management',
    type: 'link',
    icon: 'person',
    children: []
    // children: [
    //   { state: 'student-management/student_list', name: 'Students List', type: 'link' },
    // { state: 'student-management/student_profile', name: 'Create Student Profile', type: 'link' },
    // { state: 'student-management/student_document', name: 'Student Document', type: 'link' },
    // { state: 'student-management/student_explore_institutes', name: 'Shortlist Institutes', type: 'link' },
    // { state: 'student-management/student_shortlisted_universities', name: 'Shortlisted Universities', type: 'link' },
    /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Client didn't want this
      ****** OLD CODE:
      { state: 'student-management/student_payment', name: 'Payment', type: 'link' },
      { state: 'student-management/student_application_status', name: 'Application Status', type: 'link' }
      *********************************************************************
      */
    // ]
  },
  {
    state: 'recruiter-profile',
    name: 'Recruiter Details',
    type: 'link',
    icon: 'account_circle'
  },
  // {
  //   state: 'manage-staff',
  //   name: 'Manage Staff',
  //   type: 'link',
  //   icon: 'groups',
  // },
  // {
  //   state: 'school-commission',
  //   name: 'School Commission',
  //   type: 'link',
  //   icon: 'policy',
  // },
  {
    state: 'commission-policy',
    name: 'Commission Policy',
    type: 'link',
    icon: 'policy',
  },
  // {
  //   state: 'banking-information',
  //   name: 'Banking Information',
  //   type: 'link',
  //   icon: 'account_balance',
  // },
  // {
  //   state: 'mobile',
  //   name: 'Mobile and Banking',
  //   type: 'link',
  //   icon: 'school'
  // },
];

const RECRUITER_MASTER_MENUITEMS = [
  {
    state: 'dashboards/dashboard3',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  // {
  //   state: 'mobile',
  //   name: 'Mobile and Banking',
  //   type: 'link',
  //   icon: 'school'
  // },
  // {
  //   state: 'manage-staff',
  //   name: 'Manage Staff',
  //   type: 'link',
  //   icon: 'person',
  // },
];

const ASSOCIATE = [
  {
    state: 'dashboards/dashboard3',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  {
    state: 'recruiter-explore-universities',
    name: 'Explore Institute',
    type: 'link',
    icon: 'search'
  },
  {
    state: 'student-management/student_list',
    name: 'Student Management',
    type: 'link',
    icon: 'person',
    children: []
    // children: [
    //   { state: 'student-management/student_list', name: 'Students List', type: 'link' },
    // { state: 'student-management/student_profile', name: 'Create Student Profile', type: 'link' },
    // { state: 'student-management/student_document', name: 'Student Document', type: 'link' },
    // { state: 'student-management/student_explore_institutes', name: 'Shortlist Institutes', type: 'link' },
    // { state: 'student-management/student_shortlisted_universities', name: 'Shortlisted Universities', type: 'link' },
    /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Client didn't want this
      ****** OLD CODE:
      { state: 'student-management/student_payment', name: 'Payment', type: 'link' },
      { state: 'student-management/student_application_status', name: 'Application Status', type: 'link' }
      *********************************************************************
      */
    // ]
  },
  // {
  //   state: 'mobile',
  //   name: 'Mobile and Banking',
  //   type: 'link',
  //   icon: 'school'
  // },
  // {
  //   state: 'school-commission',
  //   name: 'School Commission',
  //   type: 'link',
  //   icon: 'policy',
  // },
  // {
  //   state: 'commission-policy',
  //   name: 'Commission Policy',
  //   type: 'link',
  //   icon: 'policy',
  // },
];

@Injectable({
  providedIn: 'root'
  })
export class MenuItems {
  private recruiter: IRecruiterSession;
  private organizationstaffList = [...RECRUITER_ORGANIZATION_MENUITEMS];
  private freelancerstaffList = [...RECRUITER_FREELANCER_MENUITEMS];
  constructor(
    private authService: AuthenticationService,
    private store: Store,
    private sharedService: SharedService
  ) {
    this.store.pipe(select(recruiterQuery.recruiterSessionSelect)).subscribe((recruiter: IRecruiterSession) => {
      if (recruiter) {
        this.recruiter = recruiter;
        this.store.dispatch(new GetStaffList(this.recruiter.userId, this.recruiter.salesforceId));
      }
    });
    this.store.pipe(select(recruiterQuery.staffListSelect)).subscribe(async staffLists => {
      if (staffLists) {
        const staffList = [];
        // staffList.push({
        //   state: `/dashboards/dashboard3/${this.recruiter.roleId}/${this.recruiter.userId}`,
        //   name: `${this.recruiter.firstName} (Recruiter)`,
        //   type: 'link',
        //   id: this.recruiter.userId
        // });
        for await (const temp of staffLists) {
          if (temp.status) {
            // staffList.push({
            //   state: `/dashboards/dashboard3/7/${temp.id}`,
            //   name: temp.firstName,
            //   type: 'link',
            //   id: temp.id
            // });
          }
        }
        if (staffList.length > 0) {
          this.organizationstaffList.find(temp => temp.name === 'Dashboard').type = 'sub';
          this.freelancerstaffList.find(temp => temp.name === 'Dashboard').type = 'sub';
        } else {
          this.organizationstaffList.find(temp => temp.name === 'Dashboard').type = 'link';
          this.freelancerstaffList.find(temp => temp.name === 'Dashboard').type = 'link';
        }
        this.organizationstaffList.find(temp => temp.name === 'Dashboard').children = staffList;
        this.freelancerstaffList.find(temp => temp.name === 'Dashboard').children = staffList;
        this.sharedService.isRecruterMenuBehaviorSubject.next(true);
      }
    });
  }

  getMenuitem(): IMenu[] {
    let MENUITEMS: IMenu[];
    if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.STUDENT]
      && localStorage.getItem('university') === 'UWLA') {
      MENUITEMS = STUDENT_MENUITEMS_NOT_HUB;
    } else if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.STUDENT]
      && localStorage.getItem('university') !== 'UWLA') {
      MENUITEMS = STUDENT_MENUITEMS_HUB;
    } else if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.RECRUITER_FREELANCER]) {
      MENUITEMS = RECRUITER_FREELANCER_MENUITEMS;
    } else if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.RECRUITER_ORGANIZATION]) {
      MENUITEMS = RECRUITER_ORGANIZATION_MENUITEMS;
    } else if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.RECRUITER_MASTER]) {
      MENUITEMS = RECRUITER_MASTER_MENUITEMS;
    } else if (this.authService.getLoggedInUser() && this.authService.getLoggedInUser().role === ERole[ERole.ASSOCIATE]) {
      MENUITEMS = ASSOCIATE;
    } else {
      window.location.reload();
    }

    return MENUITEMS;
  }
}
