export const countriesDegrees: ICountryDegree[][] = [
  [
    {
      country: 'USA',
      degrees: []
    },
    {
      country: 'Canada',
      degrees: []
    },
    {
      country: 'UK',
      degrees: []
    }
  ]
];

export interface ICountryDegree {
  country: string;
  degrees: string[];
}

export const proposedPaymentStatus = {
  accepted: false,
  paid: false
};
