import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { StudentDocuments } from '../../store/student-management/student-management.actions';
import { studentManagementQuery } from '../../store/student-management/student-management.selectors';

@Injectable({
  providedIn: 'root'
  })
export class SharedService {
  public isFullArrayOptionBehaviorSubject = new BehaviorSubject<boolean>(false);
  public isFullArrayOptionObservable = this.isFullArrayOptionBehaviorSubject.asObservable();

  public timelineHorizontalBehaviorSubject = new BehaviorSubject<any>(null);
  public timelineHorizontalObservable = this.timelineHorizontalBehaviorSubject.asObservable();

  public allDocumentsUploadedBehaviorSubject = new BehaviorSubject<boolean>(false);
  public allDocumentsUploadedObservable = this.allDocumentsUploadedBehaviorSubject.asObservable();

  public allAdditionalDocumentsUploadedBehaviorSubject = new BehaviorSubject<boolean>(false);
  public allAdditionalDocumentsUploadedObservable = this.allAdditionalDocumentsUploadedBehaviorSubject.asObservable();

  public isRecruterMenuBehaviorSubject = new BehaviorSubject<boolean>(false);
  public isRecruterMenuObservable = this.isRecruterMenuBehaviorSubject.asObservable();

  public isEditableUniversityBehaviorSubject = new BehaviorSubject<boolean>(false);
  public isEditableUniversityObservable = this.isEditableUniversityBehaviorSubject.asObservable();

  public isEditableUniversityDialogBehaviorSubject = new BehaviorSubject<any>(null);
  public isEditableUniversityDialogObservable = this.isEditableUniversityDialogBehaviorSubject.asObservable();

  public universitySelectedBehaviorSubject = new BehaviorSubject<any>(null);
  public universitySelectedDialogObservable = this.universitySelectedBehaviorSubject.asObservable();

  universitiesList = [
    {
      id: 1,
      name: 'University of West Los Angeles',
      terms: ['Spring', 'Spring-2', 'Summer', 'Fall']
    },
    {
      id: 2,
      name: 'St Francis College',
      terms: ['Spring 1', 'Spring 2', 'Summer', 'Fall 1', 'Fall 2'],
    },
    {
      id: 3,
      name: 'Campbellsville University',
      terms: ['Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 5,
      name: 'Anderson University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 6,
      name: 'University of the Cumberlands',
      terms: ['Spring 1', 'Spring 2', 'Summer 1', 'Summer 2', 'Fall 1', 'Fall 2'],
    },
    {
      id: 7,
      name: 'Adelphi University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 8,
      name: 'Cumberland University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 9,
      name: 'Golden Gate University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 10,
      name: 'Faulkner University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 11,
      name: 'University of Toledo',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 12,
      name: 'Marshall University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 13,
      name: 'University of Lousville',
      terms: ['Spring', 'Summer', 'Fall'],
    },
  ];
  programsUniList = [
    {
      name: 'MSLMT',
      long: 'Master of Science in Leadership, Management, and Technology',
      universityId: 1
    }, {
      name: 'BSBA',
      long: 'Bachelors of Science in Business Administration',
      universityId: 1
    },
    {
      name: 'MSPM',
      long: 'MS Project Management - STEM',
      universityId: 2
    },
    {
      name: 'MSITM',
      long: 'MS Information Technology Management - STEM',
      universityId: 2
    },
    {
      name: 'MSDM',
      long: 'MS Digital Marketing - STEM',
      universityId: 2
    },
    {
      name: 'MSBA',
      long: 'MS Business Analytics - STEM',
      universityId: 2
    },
    {
      name: 'MSHM',
      long: 'MS Healthcare Management - STEM',
      universityId: 2
    },
    {
      name: 'MSBM',
      long: 'MS Business Management - STEM',
      universityId: 2
    },
    {
      name: 'MSSIE',
      long: 'M.S.Management(Social Innovation & Entrepreneurship)',
      universityId: 2
    },
    {
      name: 'MSPA',
      long: 'MS Professional Accountancy',
      universityId: 2
    },
    {
      name: 'BSA',
      long: 'BS in Accounting - Non STEM',
      universityId: 2
    },
    {
      name: 'BSB',
      long: 'BS in Biology - STEM',
      universityId: 2
    },
    {
      name: 'BSBS',
      long: 'BS in Biomedical Science - STEM',
      universityId: 2
    },
    {
      name: 'BSC',
      long: 'BS in Chemistry - STEM',
      universityId: 2
    },
    {
      name: 'BSF',
      long: 'BS in Finance - Non STEM',
      universityId: 2
    },
    {
      name: 'BSIT',
      long: 'BS in Information Technology - STEM',
      universityId: 2
    },
    {
      name: 'BSM',
      long: 'BS in Mathematics - STEM',
      universityId: 2
    },
    {
      name: 'BSMT',
      long: 'BS in Medical Technology - STEM',
      universityId: 2
    },
    {
      name: 'BAE',
      long: 'BA in Economics - Non STEM',
      universityId: 2
    },
    {
      name: 'BAP',
      long: 'BA in Psychology - STEM',
      universityId: 2
    },
    {
      name: 'BABA',
      long: 'BA in Communication Arts(Digital Media concentration only) - STEM',
      universityId: 2
    },
    {
      name: 'ITA',
      long: 'Executive Master of Science in Information Technology Administration - STEM',
      universityId: 4
    },
    {
      name: 'Doc',
      long: 'Doctoral',
      universityId: 4
    },
    {
      name: 'MSBA',
      long: 'Master Of Science In Business Analytics - STEM',
      universityId: 5
    },
    {
      name: 'MBA',
      long: 'Informatics Master Of Business Administration (Business Analytics Concentration)',
      universityId: 5
    },
    {
      name: 'MSCS',
      long: 'Master Of Science in Cyber security Management',
      universityId: 5
    },
    {
      name: 'MBA',
      long: 'MBA - Non STEM',
      universityId: 6
    },
    {
      name: 'AIB',
      universityId: 6,
      long: 'MS in Artificial Intelligence in business - STEM'
    },
    {
      name: 'MSF',
      long: 'MS in Finance - Non STEM',
      universityId: 6
    },
    {
      name: 'MSBBT',
      long: 'MS in Global Business with blockchain technology - STEM',
      universityId: 6
    },
    {
      name: 'MSPM',
      long: 'MS in Project management - STEM',
      universityId: 6
    },
    {
      name: 'MSSM',
      long: 'MS in Strategic management - STEM',
      universityId: 6
    },
    {
      name: 'MSCE',
      long: 'MS in Cyber Engineering - STEM',
      universityId: 6
    },
    {
      name: 'MSDS',
      long: 'MS in Data science - STEM',
      universityId: 6
    },
    {
      name: 'MSIS',
      long: 'MS in Information systems security - STEM',
      universityId: 6
    },
    {
      name: 'MSIT',
      long: 'MS in Information technology - STEM',
      universityId: 6
    },
    {
      name: 'MSOL',
      long: 'MS in Organizational leadership - Non STEM',
      universityId: 6
    },
    {
      name: 'PHDB',
      long: 'PH.D in Business',
      universityId: 6
    },
    {
      name: 'PHDIT',
      long: 'PH.D in Information technology',
      universityId: 6
    },
    {
      name: 'PHDL',
      long: 'PH.D in Leadership',
      universityId: 6
    },
    {
      name: 'MSBA',
      long: 'MS in Business Analytics - STEM',
      universityId: 7
    },
    {
      name: 'MSHI',
      long: 'MS in Health Informatics - STEM',
      universityId: 7
    },
    {
      name: 'MSSCM',
      long: 'MS in Supply Chain Management - STEM',
      universityId: 7
    },
    {
      name: 'MSITM',
      long: 'Master Of Science In Information Technology Management (STEM)',
      universityId: 8
    },
    {
      name: 'MSEM',
      long: 'MS Engineering management',
      universityId: 8
    },
    {
      name: 'MBA',
      long: 'Business Administration, Professional MBA (Hybrid)',
      universityId: 9
    },
    {
      name: 'MSDS',
      long: 'Data Science, Professional MS (Hybrid)',
      universityId: 9
    },
    {
      name: 'MSEM',
      long: 'Engineering Management, Professional MS (Hybrid)',
      universityId: 9
    },
    {
      name: 'MSIT',
      long: 'Information Technology Management, Professional MS (Hybrid)',
      universityId: 9
    },
    {
      name: 'MEBA',
      long: 'Ms Management Emphasis with business analytics - STEM',
      universityId: 10
    },
    {
      name: 'MSABA',
      long: 'Master Of Science In Applied Business Analytics',
      universityId: 11
    },
    {
      name: 'MBA',
      long: 'Informatics Master Of Business Administration',
      universityId: 11
    },
    {
      name: 'MSIS',
      long: 'Master Of Science In Information Systems - STEM',
      universityId: 12
    },
    {
      name: 'MSHI',
      long: 'Master Of Science In Health Informatics - STEM',
      universityId: 12
    },
    {
      name: 'MSBA',
      long: 'Master of Science in Business Analytics - STEM',
      universityId: 13
    },
    {
      name: 'MSAA',
      long: 'Master of Science Accounting Annalytics - STEM',
      universityId: 13
    },
    {
      name: 'MBA',
      long: 'Master of Business Administration',
      universityId: 13
    },
    {
      name: 'Bachelors',
      long: 'Bachelors',
      universityId: 13
    },
    {
      name: 'MSITM',
      long: 'Master of Science in Information Technology Management',
      universityId: 3
    },
    {
      name: 'PMBA',
      long: 'Professional Master of Business Administration',
      universityId: 3
    },
    {
      name: 'MSCS',
      long: 'Master of Science in Computer Science',
      universityId: 3
    },
    {
      name: 'MSDSAI',
      long: 'Master of Science in Data Science and Artificial Intelligence',
      universityId: 3
    },
    {
      name: 'MSCC',
      long: 'Masters of Science Cloud Computing Program',
      universityId: 3
    },
    {
      name: 'MML',
      long: 'Masters in Management and Leadership',
      universityId: 3
    },
    {
      name: 'ITM',
      long: 'Information Technology Management',
      universityId: 3
    },
    {
      name: 'PMACC',
      universityId: 3
    },
    {
      name: 'PMELS',
      universityId: 3
    },
    {
      name: 'PMHCM',
      universityId: 3
    },
    {
      name: 'PMCSC',
      universityId: 3
    },
    {
      name: 'PMHRM',
      universityId: 3
    },
    {
      name: 'PMIB',
      universityId: 3
    },
    {
      name: 'PMIT',
      universityId: 3
    },
    {
      name: 'PMMAR',
      universityId: 3
    },
    {
      name: 'BUSPM',
      universityId: 3
    },
    {
      name: 'PMSLM',
      universityId: 3
    },
    {
      name: 'MMLM',
      universityId: 3
    },
    {
      name: 'MMFS',
      universityId: 3
    },
    {
      name: 'MFT',
      universityId: 3
    },
    {
      name: 'MSCCM',
      universityId: 3
    },
    {
      name: 'DSAIM',
      universityId: 3
    },
    {
      name: 'MFTNM',
      universityId: 3
    },
  ];
  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  public getUserFromLocalStorage() {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  }

  public getPhoneCountryCodeFromService(): Observable<any> {
    return this.http.get('assets/data/country-code.json');
  }

  public readFileFromService(path: string): Observable<any> {
    return this.http.get<any>(path);
  }

  public loadStudentDocuments() {
    this.store.pipe(select(studentManagementQuery.studentDocuments)).subscribe(result => {
      if (!result) {
        let path = null;
        const university = localStorage.getItem('university') || 'HUB';
        const user = this.getUserFromLocalStorage();
        if (university === 'HUB') {
          if (user.fromUS) {
            path = 'assets/data/transferStudent-document.json';
          } else {
            path = 'assets/data/outside-country-hub.json';
          }
        } else if (university === 'UWLA') {
          if (user.fromUS) {
            path = 'assets/data/transferStudent-document.json';
          } else {
            path = 'assets/data/outside-country-uwla.json';
          }
        }

        this.readFileFromService(path).subscribe(result => {
          this.store.dispatch(new StudentDocuments(result));
        });
      }
    });
  }

  public programsList() {
    return this.http.get<any>('assets/data/programs.json');
  }

  public allProgram() {
    const obj = [
      {
        value: 'Diploma in Business Administration',
        selected: true
      }
    ];
    return obj;
  }
  public allUniversity() {
    return this.universitiesList;
  }
  public allUniPrograms() {
    const prgrams = [...this.programsUniList];
    return prgrams;
  }
  public allUniTerms() {
    // const prgrams = [...this.programsUniList];
    return ['Spring', 'Spring-1', 'Spring-2', 'Summer', 'Summer-1', 'Summer-2', 'Fall', 'Fall-1', 'Fall-2'];
  }
  public filteredProgram(value) {
    const allProrgams = [...this.programsUniList];
    const filteredObj = allProrgams.filter(x => x.name.toUpperCase().includes(value.toUpperCase()));
    return filteredObj;
  }
  public filteredTerms(value) {
    const allTerms = ['Spring', 'Spring-1', 'Spring-2', 'Summer', 'Summer-1', 'Summer-2', 'Fall', 'Fall-1', 'Fall-2'];
    const filteredObj = allTerms.filter(x => x.toUpperCase().includes(value.toUpperCase()));
    return filteredObj;
  }
  public filteredUniversity(value) {
    const allUniversitites = this.allUniversity();
    const newUniversity = [...new Set(allUniversitites)];
    const filteredObj = newUniversity.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
    return filteredObj;
  }
}
