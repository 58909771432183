<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <button *ngIf="role !== 'RECRUITER' && university !== 'UWLA'" mat-icon-button routerLink="/search">
    <mat-icon>search</mat-icon>
</button>
<button mat-icon-button class="m-r-10" (click)="showPopup()">
    <mat-icon>live_help</mat-icon>
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button> -->
<button style="line-height: 0px;margin-right: 0;" *ngIf="isNotificationShow() || isNotification" 
    [matMenuTriggerFor]="notification" mat-icon-button class="m-r-10"
    (click)="onClickViewNotification()" (menuClosed)="onClosedNotificationMenu()">
    <mat-icon [matBadge]="notificationCount" matBadgeSize="small" [matBadgeHidden]="isNotificationViewed">
        notifications
    </mat-icon>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li (click)="$event.stopPropagation();">
                <div class="drop-title" fxLayoutAlign="space-between">Notifications
                    <!-- <mat-slide-toggle color="primary" [(ngModel)]="switchNotification"
                        (change)="onSwitchNotification($event)">
                    </mat-slide-toggle> -->
                </div>
            </li>
            <li>
                <div class="message-center"  [ngStyle]="{'height':isMore ? (notificationHeight-170) +'px' : '330px' }">
                    <div>
                        <!-- Message -->
                        <a *ngFor="let notification of notifications" href="javascript:void(0)"
                            [routerLink]="
                            '/student-management/student_list/'+notification.studentSalesforceId
                            ">
                            <div class="round {{notification.round}}">
                                <!-- <i class="{{notification.icon}}"></i> -->
                                <mat-icon style="position: relative;top: 13%;">{{notification.icon}}</mat-icon>
                            </div>
                            <div class="mail-content" [matTooltip]="notification.message">
                                <h5>{{notification.subject === 'additionalDocument' ? 'Additional Document' : (notification.subject | titlecase)}}</h5>
                                <span class="mail-desc text-dot" [innerHtml]="notification.message"></span>
                                <span class="time"><b>{{notification.time}}</b></span>
                            </div>
                        </a>

                    </div>
                </div>
            </li>

            <div (click)="$event.stopPropagation();onClickShowMore()" class="notification-more p-t-10 p-b-10">
                <div class="drop-title" fxLayoutAlign="center">
                    <div class="text-hover">
                        {{isMore ? 'Less' : 'More'}}
                    </div>
                    <mat-icon *ngIf="isMore">expand_less</mat-icon>
                    <mat-icon *ngIf="!isMore">expand_more</mat-icon>
                </div>
            </div>

        </ul>
    </div>
</mat-menu>
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <button [matMenuTriggerFor]="message" mat-icon-button class="m-r-10">
    <mat-icon>email</mat-icon>
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #message="matMenu" class="mymessage">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">You have 4 new Mymessages</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        //
                        <a href="#" *ngFor="let mymessage of mymessages">
                            <div class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </div>
                            <div class="mail-content">
                                <h5>{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </div>
                        </a>
                        //
                    </perfect-scrollbar>
                </div>
            </li>
        </ul>
    </div>
</mat-menu> -->
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> -->
    <mat-icon style="line-height: 28px;">account_circle</mat-icon>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button> -->
        <div class="my-profile">
            <div class="profile-img">
                <img src="assets/images/background/businessman-64.png" alt="user"> 
                <!-- <img src="https://img.icons8.com/ios-glyphs/30/000000/client-management--v2.gif"/> -->
                <!-- <img src="https://img.icons8.com/ios/50/000000/user--v3.png"/> -->
                <!-- <i class="fas fa-user-tie" style="margin-left: 5px"></i> -->
        
            </div>
        </div>
        <div fxLayout="column" class="outline" style="text-align:center; font-size:14px;">
            <span>{{user.firstName | titlecase}} {{ user.lastName | titlecase}}</span>
            <span *ngIf="role === 'RECRUITER_ORGANIZATION' 
            || role === 'RECRUITER_FREELANCER'">Agent ID: {{user.crmUniqueId ? user.crmUniqueId : 'N/A' }}</span>
        </div>
        
    <button mat-menu-item routerLink="/changepassword">
        <mat-icon>enhanced_encryption</mat-icon> Change Password
    </button>
    <button mat-menu-item (click)="notificationSettings()">
        <mat-icon>settings</mat-icon> Notification Settings
    </button>
    <button mat-menu-item (click)="onClickLogout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>