import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { EUserEndPoint } from 'src/app/store/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (
      currentUser
      && !request.url.includes(EUserEndPoint.ADD_USER)
      && !request.url.includes(EUserEndPoint.LOGIN)
      && !request.url.includes(EUserEndPoint.FORGOT_PASSWORD_STUDENT)
      && !request.url.includes(EUserEndPoint.FORGOT_PASSWORD_RECRUITER)
    ) {
      request = request.clone({
        setHeaders: {
          'x-access-token': currentUser.accessToken,
        }
      });
    }

    const university = localStorage.getItem('university') || 'HUB';
    request = request.clone({
      setHeaders: {
        university
      }
    });

    return next.handle(request);
  }
}
