import { Action } from '@ngrx/store';

import { ILockStudent } from 'src/app/pages/recruiter/student-management/student-management.model';
import { IDocument } from 'src/app/pages/student/student-application/student-application.model';
import { ICreateStudent, IInviteStudent } from '../model/recruiter.model';
import {
  IDashboardMetrics,
  IApplyCourseSuccess,
  IApplicationStatus,
  IUniversities,
  ICourses,
  IApplications,
  IApplyUniversityApplications,
  IShortlistedApplications,
  IPayNowLink,
  IProposedPaymentStatus
} from '../model/student-management.model';

export enum EStudentManagementActions {
  InviteStudent = '[Student] Invite',
  GetStudentList = '[Student] Get List',
  GetStudentListSuccess = '[Student] Get List Success',
  DeleteFromInviteFailed = '[Student] Delete the student invite failed list',
  DeleteFromInviteFailedSuccess = '[Student] Delete the student invite failed list Success',
  GetInviteFailedList = '[Student] Invite failed',
  GetInviteFailedListSuccess = '[Student] Invite failed list Success',
  SendStudentProfile = '[Student] Send Profile',
  SendStudentProfileSuccess = '[Student] Send Profile Success',
  GetStudentProfile = '[Student] GetStudent Profile',
  GetStudentProfileSuccess = '[Student] GetStudent Profile Success',
  GetDashboardMetrics = '[Student] Get Dashboard Metrics',
  DashboardMetrics = '[Student] Dashboard Metrics',
  GetLeadsbyCard = '[Student] Get Dashboard Card',
  LeadsbyCard = '[Student] Dashboard Card',
  ApplyCourse = '[Student] Apply Course',
  GetApplicationStatus = '[Student] Get Application Status',
  GetApplicationStatusSuccess = '[Student] Get Application Status Success',
  ApplyCourseSuccess = '[Student] Apply Course  Success',
  GetStudentLoginDetail = '[Student] Get Student Login Detail',
  LockStudent = '[Student] Lock Student',
  GetDegreeList = '[Student] Get Degree List',
  GetDegreeListSuccess = '[Student] Get Degree List Success',
  GetUniversityList = '[Student] Get University List',
  GetUniversityListV2 = '[Student] Get University List From CRM',
  GetUniversityListSuccess = '[Student] Get University List Success',
  GetUniversityListSuccessV2 = '[Student] Get University List Success From CRM',
  GetCourseList = '[Student] Get Course List',
  GetCourseListSuccess = '[Student] Get Course List Success',
  ApplyUniversityApplications = '[Student] Apply University Applications',
  ApplyUniversityApplicationsFailure = '[Student] Apply University Applications Failure ',
  GetAppliedApplications = '[Student] Get Applied Applications',
  GetAppliedApplicationsSuccess = '[Student] Get Applied Applications Success',
  GetShortlistedApplications = '[Student] Get Shortlisted Applications',
  GetShortlistedApplicationsSuccess = '[Student] Get Shortlisted Applications Success',
  AcceptShortlistedApplications = '[Student] Accept Shortlisted Applications',
  StudentDocuments = '[Student] Student Documents',
  PayNow = '[Student] Pay Now Payment',
  PayNowSuccess = '[Student] Pay Now Success',
  UniversityProposedPaymentStatus = '[Student] University Proposed Payment Status',
  UniversityProposedPaymentStatusSuccess = '[Student] University Proposed Payment Status Success',
  GetExploreUniversity = '[Student] GetExplore University',
  GetExploreUniversitySuccess = '[Student] GetExplore University Success',
  SaveUniversityApplications = '[Student] Save University Applications',
  GetSaveUniversityApplications = '[Student] Get Saved University',
  GetSaveUniversityApplicationsSuccess = '[Student] Get Saved University Success',
  UpdateDocumentCompletion = '[Student] Update Document Completion',
  UpdateDocumentCompletionSuccess = '[Student] Update Document Completion Success',
  GetStudentApplicationStatus = '[Student] Get Student Application Status',
  GetStudentApplicationStatusSuccess = '[Student] Get Student Application Status Success',
  GetStudentNotification = '[Student] Get Student Notification',
  GetStudentNotificationSuccess = '[Student] Get Student Notification Success',
  SaveStudentFavoriteUniversityList = '[Student] Save Student University Favorite University',
  GetStudentSavedFavoriteUniversityList = '[Student] Get Student Saved Favorite University',
  GetStudentSavedFavoriteUniversityListSuccess = '[Student] Get Student Saved Favorite University Success',
  CreateStudent = '[Student] Create',
  CreateStudentSuccess = '[Student] Create Success',
  ResetCreateStudentSuccess = '[Student] Reset Create Success',
  UniverSityList = '[Student] UniverSity List',
}

export class StudentManagementAction implements Action {
  readonly type: string;
  inviteStudent?: IInviteStudent;
  studentList?: any;
  studentProfile?: any;
  dashboardMetrics?: IDashboardMetrics[];
  leadsbyCard?: any[];
  applyCourseSuccess?: IApplyCourseSuccess;
  applicationStatus?: IApplicationStatus[];
  universityList?: IUniversities[];
  degreeList?: string[];
  courseList?: ICourses[];
  appliedApplications?: IApplications[];
  shortlistedApplications?: IShortlistedApplications;
  documents?: IDocument[];
  payNow?: IPayNowLink;
  proposedPaymentStatus?: IProposedPaymentStatus;
  savedUniversity?: any;
  createStudentInfo?: any;
}

export class InviteStudent implements StudentManagementAction {
  public readonly type = EStudentManagementActions.InviteStudent;
  constructor(public inviteStudent: IInviteStudent) { }
}

export class GetStudentList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentList;
  constructor(public id: number, public roleId: number) { }
}

export class GetStudentListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentListSuccess;
  constructor(public studentList: any) { }
}

export class GetInviteFailedList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetInviteFailedList;
  constructor(public id: number, public roleId: number, public salesforceId:any) { }
}

export class GetInviteFailedListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetInviteFailedListSuccess;
  constructor(public failedList: any) { }
}

export class DeleteFromInviteFailed implements StudentManagementAction {
  public readonly type = EStudentManagementActions.DeleteFromInviteFailed;
  constructor(public element: any) { }
}

export class DeleteFromInviteFailedSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.DeleteFromInviteFailedSuccess;
  constructor(public deleteFailedList: any) { }
}

export class SendStudentProfile implements StudentManagementAction {
  public readonly type = EStudentManagementActions.SendStudentProfile;
  constructor(public studentProfile: any) { }
}

export class SendStudentProfileSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.SendStudentProfileSuccess;
  constructor(public studentProfile: any) { }
}

export class GetStudentProfile implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentProfile;
  constructor(public contactId: any) { }
}

export class GetStudentProfileSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentProfileSuccess;
  constructor(public studentProfile: any) { }
}

export class GetDashboardMetrics implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetDashboardMetrics;
  constructor(public id: number, public roleId?: number) { }
}

export class DashboardMetrics implements StudentManagementAction {
  public readonly type = EStudentManagementActions.DashboardMetrics;
  constructor(public dashboardMetrics: IDashboardMetrics[]) { }
}

export class GetLeadsbyCard implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetLeadsbyCard;
  constructor(public id: number, public roleId?: number, public cardType?: string) { }
}

export class LeadsbyCard implements StudentManagementAction {
  public readonly type = EStudentManagementActions.LeadsbyCard;
  constructor(public leadsbyCard: any[]) { }
}

export class ApplyCourse implements StudentManagementAction {
  public readonly type = EStudentManagementActions.ApplyCourse;
  constructor(public formCode: string) { }
}

export class ApplyCourseSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.ApplyCourseSuccess;
  constructor(public applyCourseSuccess: IApplyCourseSuccess) { }
}

export class GetApplicationStatus implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetApplicationStatus;
  constructor() { }
}

export class GetApplicationStatusSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetApplicationStatusSuccess;
  constructor(public applicationStatus: IApplicationStatus[]) { }
}

export class GetStudentLoginDetail implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentLoginDetail;
  constructor(public contactId: string) { }
}

export class LockStudent implements StudentManagementAction {
  public readonly type = EStudentManagementActions.LockStudent;
  constructor(public lockStudent: ILockStudent) { }
}

export class GetDegreeList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetDegreeList;
  constructor(public country: string, public salesforceId:any) { }
}

export class GetDegreeListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetDegreeListSuccess;
  constructor(public degreeList: string[]) { }
}

export class GetUniversityList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetUniversityList;
  constructor(public country: string, public degree: string,
    public courseName: string, public salesforceId:any) { }
}
export class GetUniversityListV2 implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetUniversityListV2;
  constructor() { }
}

export class GetUniversityListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetUniversityListSuccess;
  constructor(public universityList: IUniversities[]) { }
}
export class GetUniversityListSuccessV2 implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetUniversityListSuccessV2;
  constructor(public universityList: any) { }
}

export class GetCourseList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetCourseList;
  constructor(public country: string, public degree: string, public salesforceId:any) { }
}

export class GetCourseListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetCourseListSuccess;
  constructor(public courseList: ICourses[]) { }
}

export class ApplyUniversityApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.ApplyUniversityApplications;
  constructor(public applyUniversityApplications: IApplyUniversityApplications) { }
}
export class ApplyUniversityApplicationsFailure implements StudentManagementAction {
  public readonly type = EStudentManagementActions.ApplyUniversityApplicationsFailure;
  constructor(public error: any) { }
}

export class SaveUniversityApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.SaveUniversityApplications;
  constructor(public saveUniversityApplications: IApplyUniversityApplications) { }
}

export class GetAppliedApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetAppliedApplications;
  constructor(public contactId: any) { }
}

export class GetAppliedApplicationsSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetAppliedApplicationsSuccess;
  constructor(public appliedApplications: IApplications[]) { }
}

export class GetShortlistedApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetShortlistedApplications;
  constructor(public contactId: any) { }
}

export class GetShortlistedApplicationsSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetShortlistedApplicationsSuccess;
  constructor(public shortlistedApplications: IShortlistedApplications) { }
}

export class AcceptShortlistedApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.AcceptShortlistedApplications;
  constructor(public acceptShortlistedApplications: IApplyUniversityApplications) { }
}

export class StudentDocuments implements StudentManagementAction {
  public readonly type = EStudentManagementActions.StudentDocuments;
  constructor(public documents: IDocument[]) { }
}

export class PayNow implements StudentManagementAction {
  public readonly type = EStudentManagementActions.PayNow;
  constructor(public paymentData: any) { }
}

export class PayNowSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.PayNowSuccess;
  constructor(public payNow: IPayNowLink) { }
}

export class UniversityProposedPaymentStatus implements StudentManagementAction {
  public readonly type = EStudentManagementActions.UniversityProposedPaymentStatus;
  constructor(public contactId: any) { }
}

export class UniversityProposedPaymentStatusSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.UniversityProposedPaymentStatusSuccess;
  constructor(public proposedPaymentStatus: IProposedPaymentStatus) { }
}

export class GetSaveUniversityApplications implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetSaveUniversityApplications;
  constructor(public contactId: any) { }
}

export class GetSaveUniversityApplicationsSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetSaveUniversityApplicationsSuccess;
  constructor(public savedUniversity: any) { }
}

export class GetStudentApplicationStatus implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentApplicationStatus;
  constructor(public contactId: any) { }
}

export class GetStudentApplicationStatusSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentApplicationStatusSuccess;
  constructor(public applicationStatus: any) { }
}

export class GetExploreUniversity implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetExploreUniversity;
  constructor(public contactId: any) { }
}

export class GetExploreUniversitySuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetExploreUniversitySuccess;
  constructor(public exploreUniversity: any) { }
}

export class UpdateDocumentCompletion implements StudentManagementAction {
  public readonly type = EStudentManagementActions.UpdateDocumentCompletion;
  constructor(public documentCompletion: any) { }
}

export class UpdateDocumentCompletionSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.UpdateDocumentCompletionSuccess;
  constructor(public documentCompletion: any) { }
}

export class GetStudentNotification implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentNotification;
  constructor() { }
}

export class GetStudentNotificationSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentNotificationSuccess;
  constructor(public studentNotification: any) { }
}

export class GetStudentSavedFavoriteUniversityList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentSavedFavoriteUniversityList;
  constructor(public contactId: any) { }
}

export class GetStudentSavedFavoriteUniversityListSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.GetStudentSavedFavoriteUniversityListSuccess;
  constructor(public savedStudentFavoriteUniversity: any) { }
}
export class SaveStudentFavoriteUniversityList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.SaveStudentFavoriteUniversityList;
  constructor(public studentFavoriteUniversity: any) { }
}

export class CreateStudent implements StudentManagementAction {
  public readonly type = EStudentManagementActions.CreateStudent;
  constructor(public createStudent: ICreateStudent) { }
}

export class CreateStudentSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.CreateStudentSuccess;
  constructor(public createStudentInfo: any) { }
}
export class ResetCreateStudentSuccess implements StudentManagementAction {
  public readonly type = EStudentManagementActions.ResetCreateStudentSuccess;
  constructor() { }
}
export class UniverSityList implements StudentManagementAction {
  public readonly type = EStudentManagementActions.SendStudentProfile;
  constructor(public studentProfile: any) { }
}

export type StudentManagementActions =
  | InviteStudent
  | GetStudentList
  | GetStudentListSuccess
  | DeleteFromInviteFailed
  | DeleteFromInviteFailedSuccess
  | SendStudentProfile
  | SendStudentProfileSuccess
  | GetStudentProfile
  | GetStudentProfileSuccess
  | GetDashboardMetrics
  | DashboardMetrics
  | GetLeadsbyCard
  | LeadsbyCard
  | ApplyCourse
  | ApplyCourseSuccess
  | GetApplicationStatus
  | GetApplicationStatusSuccess
  | GetStudentLoginDetail
  | LockStudent
  | GetDegreeList
  | GetDegreeListSuccess
  | GetUniversityList
  | GetUniversityListV2
  | GetUniversityListSuccess
  | GetUniversityListSuccessV2
  | GetCourseList
  | GetCourseListSuccess
  | ApplyUniversityApplications
  | ApplyUniversityApplicationsFailure
  | GetAppliedApplications
  | GetAppliedApplicationsSuccess
  | GetShortlistedApplications
  | GetShortlistedApplicationsSuccess
  | AcceptShortlistedApplications
  | StudentDocuments
  | PayNow
  | PayNowSuccess
  | UniversityProposedPaymentStatus
  | UniversityProposedPaymentStatusSuccess
  | GetExploreUniversity
  | GetExploreUniversitySuccess
  | SaveUniversityApplications
  | GetSaveUniversityApplications
  | GetSaveUniversityApplicationsSuccess
  | GetInviteFailedList
  | GetInviteFailedListSuccess
  | UpdateDocumentCompletion
  | UpdateDocumentCompletionSuccess
  | GetStudentApplicationStatus
  | GetStudentApplicationStatusSuccess
  | GetStudentNotification
  | GetStudentNotificationSuccess
  | GetStudentSavedFavoriteUniversityList
  | GetStudentSavedFavoriteUniversityListSuccess
  | SaveStudentFavoriteUniversityList
  | CreateStudent
  | CreateStudentSuccess
  | ResetCreateStudentSuccess
  | UniverSityList;
