import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  IUserHttp,
  EUserEndPoint,
  IUser,
  IChangePasswordRequest,
  IForgotPasswordRequest,
  INewPasswordRequest,
  ILogout,
  ERole,
  IRefreshTokenRequest,
  IActivateStudent,
  ILogin,
} from '../model/user.model';
import { userQuery } from './users.selectors';
import { IRecruiterSession } from '../model/recruiter.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl = `${environment.baseUrl}`;
  public role: string;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.store.pipe(select(userQuery.roleSelector)).subscribe((role: string) => {
      this.role = role;
    });
  }

  public registerStudentFromService(requestBody: IUser): Observable<any> {
    const url: string = this.baseUrl + EUserEndPoint.ADD_USER;
    return this.http.post<IUser>(url, requestBody, { observe: 'response' })
      .pipe(map(response => {
        if (response.status !== 208) {
          return response;
        }
        return 208;
      }));
  }

  public loginFromService(requestBody: ILogin): Observable<IUser> {
    const url: string = this.baseUrl + EUserEndPoint.LOGIN;
    return this.http.post<IUser>(url, requestBody);
  }

  public validateEmailFromService(requestBody: any) {
    const url: string = this.baseUrl + EUserEndPoint.EMAIL_ID_VALIDATOR;
    return this.http.post<any>(url, requestBody);
  }

  public logoutFromService(requestBody: ILogout) {
    const url: string = this.baseUrl + EUserEndPoint.LOGOUT;
    return this.http.post<ILogout>(url, requestBody);
  }

  public changePasswordFromService(requestBody: IChangePasswordRequest) {
    const urlEndpoint: string = (this.role === ERole[ERole.STUDENT])
      ? EUserEndPoint.CHANGE_PASSWORD_STUDENT : EUserEndPoint.CHANGE_PASSWORD_RECRUITER;

    const url: string = this.baseUrl + urlEndpoint;
    return this.http.post<any>(url, requestBody);
  }

  public forgotPasswordFromService(requestBody: IForgotPasswordRequest) {
    let urlEndpoint: string;

    switch (this.role) {
      case ERole[ERole.STUDENT]:
        urlEndpoint = EUserEndPoint.FORGOT_PASSWORD_STUDENT;
        break;

      case 'RECRUITER':
        urlEndpoint = EUserEndPoint.FORGOT_PASSWORD_RECRUITER;
        break;

      case ERole[ERole.RECRUITER_ORGANIZATION]:
        urlEndpoint = EUserEndPoint.FORGOT_PASSWORD_RECRUITER;
        break;

      case ERole[ERole.RECRUITER_FREELANCER]:
        urlEndpoint = EUserEndPoint.FORGOT_PASSWORD_RECRUITER;
        break;

      case ERole[ERole.ASSOCIATE]:
        urlEndpoint = EUserEndPoint.FORGOT_PASSWORD_ASSOCIATE;
        break;

      default:
        break;
    }

    const url: string = this.baseUrl + urlEndpoint;
    return this.http.post<any>(url, requestBody);
  }

  public newPasswordFromService(requestBody: INewPasswordRequest) {
    const urlEndpoint: string = EUserEndPoint[requestBody.purpose];

    const url: string = this.baseUrl + urlEndpoint;
    return this.http.post<any>(url, requestBody);
  }

  public refreshToken(requestBody: IRefreshTokenRequest) {
    const url: string = this.baseUrl + EUserEndPoint.REFRESH_TOKEN;
    return this.http.post<any>(url, requestBody);
  }

  public activateStudent(requestBody: IActivateStudent) {
    const url: string = this.baseUrl + EUserEndPoint.ACTIVATE_STUDENT;
    return this.http.post<any>(url, requestBody);
  }

  public resendLink(requestBody: any) {
    const url: string = this.baseUrl + EUserEndPoint.RESEND_LINK;
    return this.http.post<any>(url, requestBody);
  }

  getUsers(): Observable<IUserHttp> {
    return this.http.get<IUserHttp>(this.baseUrl);
  }

  get getLoggedInUser(): IUser | IRecruiterSession {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  }
}
