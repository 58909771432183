<!-- Breadcrumb -->

<div class="page-breadcrumb p-t-20 p-b-20 p-l-30 p-r-30">
	<div fxLayout="row wrap" fxLayoutAlign="end">
        <!-- <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center"> -->
            <!-- <h4 class="page-title text-themecolor m-0">{{pageInfo?.title}}</h4> -->
		<!-- </div> -->
		<div fxFlex="100" class="align-self-center" [fxLayout.gt-sm]="validationMessage ? 'row' : ''"
            [fxLayout]="validationMessage ? 'column' : ''"
            [fxLayoutAlign.gt-sm]="validationMessage ? 'space-between center' : 'end'"
            [fxLayoutAlign]="validationMessage ? 'space-between end' : 'end'">
            <div *ngIf="validationMessage" style="font-size: 14px;color: #c4b3c5;"
                fxShow="false" fxShow.gt-sm="true">{{validationMessage}}</div>
            <div *ngIf="validationMessage" style="width: 100%;font-size: 14px;color: #c4b3c5;" class="breadcrumb-item active"
                fxShow="true" fxShow.gt-sm="false">{{validationMessage}}</div>
            <div class="breadcrumb d-flex align-items-center float-right float-sm-left">
                <ul class="list-style-none d-flex align-items-center">
                    <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                        <!-- <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                            <a href='javascript:void(0)'>{{url.title}}</a>
                        </li> -->
                        <li class="breadcrumb-item active" *ngIf="!last">{{url.title}}</li>
                        <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
</div>