import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap, tap, mergeMap, catchError
} from 'rxjs/operators';

import { UserService } from './users.service';
import { ERole, IUser, Recruiter } from '../model/user.model';
import { ShowSuccessMsg, ShowErrorMessage, RedirectLogin } from '../app.actions';
import {
  EUserActions,
  AddUser,
  AddUserSuccess,
  AddUserFailure,
  Login,
  LoginSuccess,
  LoginFailure,
  Logout,
  LoggedOut,
  LoggedInUser,
  EmailIdValidator,
  EmailIdValidatorFailure,
} from './users.actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const resolvePostLoginNavigation = (user, router: Router, returnUrl?: string) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('role', user.role);
  localStorage.setItem('temp_role', user.role);
  if (user.role === 'STUDENT') {
    localStorage.setItem('loginRole', 'STUDENT');
  } else if (Recruiter(user.role) === 'RECRUITER') {
    localStorage.setItem('loginRole', 'RECRUITER');
  }

  // if (returnUrl) {
  //   return router.navigateByUrl(returnUrl);
  // }

  if (user.role === ERole[ERole.STUDENT]) {
    router.navigate(['/student-profile']);
    // router.navigate(['/student-dashboard']);
  } else if (Recruiter(user.role) === 'RECRUITER') {
    router.navigate(['/dashboards/dashboard3']);
  } else if (user.role === ERole[ERole.ASSOCIATE]) {
    router.navigate(['/dashboards/dashboard3']);
  }
};

export const redirectToLogin = (router) => {
  router.navigateByUrl('/authentication/recruiter/login');
};

@Injectable()
export class UserEffects {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private userService: UserService,
  ) { }

  // @Effect()
  addUser$ = createEffect(() => this.actions$.pipe(
    ofType<AddUser>(EUserActions.AddUser),
    switchMap(({ user }: AddUser) => this.userService.registerStudentFromService(user).pipe(
      tap((response) => {
        if (response.status === 200) {
          return redirectToLogin(this.router);
        }
      }),
      mergeMap((user) => {
        if (user === 208) {
          return [
            new ShowErrorMessage('Email already exists'),
          ];
        }
        return [
          new ShowSuccessMsg('You have successfully registered'),
          new AddUserSuccess(user),
        ];
      }),
      catchError(error => of(
        new AddUserFailure(error),
        new ShowErrorMessage(error),
      ))
    ))
  ));

  // @Effect()
  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(EUserActions.Login),
    switchMap(({ user }: Login) => this.userService.loginFromService(user).pipe(
      tap(user => resolvePostLoginNavigation(user, this.router, this.route.snapshot.queryParams.returnUrl)),
      mergeMap((user: IUser) => [
        new LoggedInUser(user),
        new LoginSuccess(user.role, user),
        new ShowSuccessMsg('Login Successful'),
      ]),
      catchError(error => of(
        new LoginFailure(error),
        // new ShowErrorMessage(error),
      ))
    ))
  ));

  // @Effect()
  emailIdValidator$ = createEffect(() => this.actions$.pipe(
    ofType<EmailIdValidator>(EUserActions.EmailIdValidator),
    switchMap(({ data }: EmailIdValidator) => this.userService.validateEmailFromService(data).pipe(
      mergeMap(() => [
      ]),
      catchError(error => of(
        new ShowErrorMessage(error),
        // new EmailIdValidatorFailure(error),
      ))
    ))
  ));

  // @Effect()
  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(EUserActions.Logout),
    switchMap(({ payload }: Logout) => this.userService.logoutFromService(payload).pipe(
      mergeMap(() => [
        new LoggedOut(),
        new RedirectLogin(),
        new ShowSuccessMsg('Logout Successfully'),
      ]),
      catchError(error => of(
        new LoggedOut(),
        new RedirectLogin(),
        new ShowErrorMessage(error),
      ))
    ))
  ));
}
