import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EStudentMangementEndPoints } from '../../store/model/student-management.model';
import { environment } from '../../../environments/environment';

export interface NotificationEvent {
  add?: boolean;
  command: string;
  id?: string;
  notification?: Notification;
}
export interface Icons {
  alert: string;
  error: string;
  info: string;
  warn: string;
  success: string;
}

export const defaultIcons: Icons = {
  alert: `
        <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
        </svg>
    `,
  error: `
        <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
        </svg>
    `,
  info: `
        <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>
        </svg>
    `,
  success: `
        <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
        </svg>
    `,
  warn: `
        <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="64" viewBox="0 0 64 64" height="64">
          <circle cx="32.086" cy="50.142" r="2.256"/>
          <path d="M30.08 25.012V42.32c0 1.107.897 2.005 2.006 2.005s2.006-.897 2.006-2.005V25.012c0-1.107-.897-2.006-2.006-2.006s-2.006.898-2.006 2.006z"/>
          <path d="M63.766 59.234L33.856 3.082c-.697-1.308-2.844-1.308-3.54 0L.407 59.234c-.331.622-.312 1.372.051 1.975.362.605 1.015.975 1.72.975h59.816c.705 0 1.357-.369 1.721-.975.361-.603.381-1.353.051-1.975zM5.519 58.172L32.086 8.291l26.568 49.881H5.519z"/>
        </svg>
    `
};

export interface Notification {
  id?: string
  type: string
  icon: string
  title?: any
  content?: any
  override?: any
  html?: any
  state?: string
  createdOn?: Date
  destroyedOn?: Date
  animate?: string
  timeOut?: number
  maxLength?: number
  pauseOnHover?: boolean
  clickToClose?: boolean
  clickIconToClose?: boolean
  theClass?: string
  click?: EventEmitter<{}>;
  clickIcon?: EventEmitter<{}>;
}

export interface Options {
  timeOut?: number;
  showProgressBar?: boolean;
  pauseOnHover?: boolean;
  lastOnBottom?: boolean;
  clickToClose?: boolean;
  clickIconToClose?: boolean;
  maxLength?: number;
  maxStack?: number;
  preventDuplicates?: boolean;
  preventLastDuplicates?: boolean | string;
  theClass?: string;
  rtl?: boolean;
  animate?: 'fade' | 'fromTop' | 'fromRight' | 'fromBottom' | 'fromLeft' | 'rotate' | 'scale';
  icons?: Icons;
  position?: ['top' | 'bottom' | 'middle', 'right' | 'left' | 'center'];
}

@Injectable({
  providedIn: 'root'
  })
export class NotificationServiceService {
  public baseUrl = environment.baseUrl;
  baseUrlCRM = environment.baseUrlCRM;
  public emitter = new Subject<NotificationEvent>();
  public icons: Icons = defaultIcons;
  public unreadCountRecBehaviorSubject = new BehaviorSubject<any>(null);
  public unreadCountRecObservable = this.unreadCountRecBehaviorSubject.asObservable();
  public viewReceivedLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public viewReceivedLeadObservable = this.viewReceivedLeadBehaviorSubject.asObservable();
  public addLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public addLeadObservable = this.addLeadBehaviorSubject.asObservable();

  constructor(
    private http: HttpClient
  ) {

  }

  public saveStudentNotification(requestBody): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.SAVE_STUDENT_NOTIFICATION;
    return this.http.post<any>(url, requestBody);
  }

  set(notification: Notification, to: boolean): Notification {
    notification.id = notification.override && notification.override.id
      ? notification.override.id
      : Math.random().toString(36).substring(3);
    notification.click = new EventEmitter<{}>();

    this.emitter.next({ command: 'set', notification, add: to });
    return notification;
  }
  success(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'success',
        icon: this.icons.success,
        override,
      },
      true
    );
  }

  error(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'error',
        icon: this.icons.error,
        override,
      },
      true
    );
  }

  alert(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'alert',
        icon: this.icons.alert,
        override,
      },
      true
    );
  }

  info(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'info',
        icon: this.icons.info,
        override,
      },
      true
    );
  }

  warn(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'warn',
        icon: this.icons.warn,
        override,
      },
      true
    );
  }

  bare(title: any = '', content: any = '', override?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: 'bare',
        icon: 'bare',
        override,
      },
      true
    );
  }
  // Remove all notifications method
  remove(id?: string): void {
    if (id) {
      this.emitter.next({ command: 'clean', id });
    } else {
      this.emitter.next({ command: 'cleanAll' });
    }
  }
  sendNotifications(data) {
    return this.http.post(`${this.baseUrlCRM}sentNotifications`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  getAllNotifications(data) {
    return this.http.post(`${this.baseUrlCRM}listOfMyNotifications`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  getSavedNotifications(email, Platform, token) {
    return this.http.get(`${this.baseUrlCRM}notifications/getSavedNotificaitons/${email}/${Platform}/${token}`, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  getUnreadCount(data) {
    return this.http.post(
      `${this.baseUrlCRM}notifications/unreadNotificationCount`,
      data,
      {
        headers: {
          'x-auth-token': 'asseccque'
        }
      }
    );
  }

  deleteNotification(data) {
    return this.http.post(`${this.baseUrlCRM}notifications/delete`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  saveNotification(data) {
    return this.http.post(`${this.baseUrlCRM}notifications/save`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  readNotification(data) {
    return this.http.post(`${this.baseUrlCRM}notificationReadStatus`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
}
