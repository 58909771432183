import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

import { MenuItems } from 'src/app/shared/models/menu-items';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
  })
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex: Number;
  childIndex: Number;
  isSubMenuOpen: Array<boolean> = [];
  isSubMenuActive: Array<boolean> = [];
  currentPath = '';
  public isNavigateTrue: boolean;
  public isStudent = false;
  public user : any;
  public role : any;

  setClickedRow(i, j) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop() {
    document.querySelector('.page-wrapper').scroll({
      top: 0,
      left: 0
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private pLocation: PlatformLocation,
    private sharedService: SharedService,
  ) {
    this.currentPath = this.router.url;
    this.getActiveLink();
    this.getSubMenuActiveLink();
    this.sharedService.isRecruterMenuObservable.subscribe(temp => {
      if (temp) {
        this.getActiveLink();
        this.getSubMenuActiveLink();
      }
    });
    this.sharedService.isEditableUniversityObservable.subscribe(temp => {
      this.isNavigateTrue = temp;
      if (!temp) {
        setTimeout(() => {
          if (!(this.router.url === '/recruiter-student-profile'
          || this.router.url === '/recruiter-student-document'
          || this.router.url === '/recruiter-shortlisted-universities'
          || this.router.url === '/recruiter-explore-institutes'
          || this.router.url === '/recruiter-student-payment'
          || this.router.url === '/recruiter-student-application-status'
          )) {
            this.isSubMenuOpen = [];
          }
          this.currentPath = this.router.url;
        }, 300);
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentPath = this.router.url;
        if (this.currentPath === '/recruiter-student-profile'
          || this.currentPath === '/recruiter-student-document'
          || this.currentPath === '/recruiter-shortlisted-universities'
          || this.currentPath === '/recruiter-explore-institutes'
          || this.currentPath === '/recruiter-student-payment'
          || this.currentPath === '/recruiter-student-application-status') {
          this.isSubMenuActive = [];
          this.getSubMenuActiveLink();
        }

        if (this.currentPath === '/recruiter-explore-universities') {
          this.isSubMenuOpen = [];
          this.isSubMenuActive = [];
        }
      }
    });

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = localStorage.getItem('role');
    console.log(this.menuItems.getMenuitem());
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onClickMenu(menuitem) {
    this.router.navigate([menuitem.state]);
  }

  getUrl() {
    return (this.pLocation as any).location.href;
  }

  public getActiveLink() {
    setTimeout(() => {
      this.menuItems.getMenuitem().forEach((element, index) => {
        const isMainMenu = this.menuItems.getMenuitem().filter(te => te.state === this.router.url.substring(1));
        if (isMainMenu.length === 0) {
          element.children?.forEach(temp => {
            if (this.router.url === '/recruiter-student-profile'
            || this.router.url === '/recruiter-student-document'
            || this.router.url === '/recruiter-shortlisted-universities'
            || this.router.url === '/recruiter-explore-institutes'
            || this.router.url === '/recruiter-student-payment'
            || this.router.url === '/recruiter-student-application-status') {
              this.isSubMenuOpen = [];
            }
            if (temp.state === this.router.url || temp.state === this.router.url.substring(1)
              || this.router.url === '/recruiter-student-profile'
              || this.router.url === '/recruiter-student-document'
              || this.router.url === '/recruiter-shortlisted-universities'
              || this.router.url === '/recruiter-explore-institutes'
              || this.router.url === '/recruiter-student-payment'
              || this.router.url === '/recruiter-student-application-status') {
              this.isSubMenuOpen[index] = true;
            }
          });
        }
      });
    });
  }

  public getSubMenuActiveLink() {
    this.menuItems.getMenuitem().forEach(element => {
      const isMainMenu = this.menuItems.getMenuitem().filter(te => te.state === this.router.url.substring(1));
      if (isMainMenu.length === 0) {
        element.children?.forEach(temp => {
          if (temp.state === 'student-management/student_profile'
          || temp.state === 'student-management/student_document'
          || temp.state === 'student-management/student_shortlisted_universities'
          || temp.state === 'student-management/student_explore_institutes'
          || temp.state === 'student-management/student_payment'
          || temp.state === 'student-management/student_application_status'
          || this.router.url === '/recruiter-student-profile'
          || this.router.url === '/recruiter-student-document'
          || this.router.url === '/recruiter-shortlisted-universities'
          || this.router.url === '/recruiter-explore-institutes'
          || this.router.url === '/recruiter-student-payment'
          || this.router.url === '/recruiter-student-application-status') {
            if (this.router.url === '/recruiter-student-profile' && temp.state === 'student-management/student_profile') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_profile')] = true;
            } else if (this.router.url === '/recruiter-student-document' && temp.state === 'student-management/student_document') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_document')] = true;
            } else if (this.router.url === '/recruiter-shortlisted-universities' && temp.state === 'student-management/student_shortlisted_universities') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_shortlisted_universities')] = true;
            } else if (this.router.url === '/recruiter-explore-institutes' && temp.state === 'student-management/student_explore_institutes') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_explore_institutes')] = true;
            } else if (this.router.url === '/recruiter-student-payment' && temp.state === 'student-management/student_payment') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_payment')] = true;
            } else if (this.router.url === '/recruiter-student-application-status' && temp.state === 'student-management/student_application_status') {
              this.isSubMenuActive[element.children.findIndex(i => i.state === 'student-management/student_application_status')] = true;
            }
          }
        });
      }
    });
  }

  public onClickMainMenu(data, index) {
    if (!this.isNavigateTrue) {
      if (!this.isSubMenuOpen[index]) {
        this.isSubMenuOpen = [];
        this.isSubMenuActive = [];
      }
      this.router.navigate(data);
      setTimeout(() => {
        this.currentPath = this.router.url;
        if (this.router.url === data.toString()) {
          this.isSubMenuOpen[index] = !this.isSubMenuOpen[index];
        }
      }, 300);
    } else {
      this.sharedService.isEditableUniversityDialogBehaviorSubject.next(data);
    }
  }

  public onClickSubmenu(data) {
    this.router.navigate(data);
    this.isSubMenuActive = [];
  }
}
