import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { recruiterQuery } from '../../../../store/recruiter/recruiter.selectors';
import { GetAllSound, NotificationSubscibe, UpdateSound } from '../../../../store/recruiter/recruiter.actions';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
  })
export class NotificationSettingsComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  soundList: any;
  audio = new Audio();
  userData: any;
  sound = false;
  selectedSound: any;
  showNotifications = false;

  constructor(private store: Store) {

  }

  ngOnInit(): void {
    this.store.dispatch(new GetAllSound());

    this.subscriptions.push(
      this.store.pipe(select(recruiterQuery.soundList)).subscribe(async response => {
        if (response) {
          this.soundList = _.cloneDeep(response);
        }
      })
    );

    this.userData = JSON.parse(localStorage.getItem('user') || '{}');
    if (this.userData.notification_subscribe == null) {
      this.userData.notification_subscribe = true;
    } else {
      this.sound = this.userData.notification_subscribe;
    }
    if (this.userData.notification_subscribe == true) {
      this.sound = true;
    }
    this.showNotifications = this.userData.notification_subscribe;
    this.selectedSound = this.userData.soundId;
  }

  playAudio(item) {
    this.audio = new Audio();
    const source = document.createElement('source');
    source.setAttribute('src', item.soundPath);
    source.setAttribute('type', 'audio/mpeg');
    this.audio.append(source);
    this.audio.load();
    const playPromise = this.audio.play();
    if (playPromise !== null) {
      playPromise.catch(() => {
        this.audio.play();
      });
    }
    const data = {
      recruiterId: this.userData.salesforceId,
      soundId: item.id
    };
    this.store.dispatch(new UpdateSound(data));
    this.userData.soundId = item.id;
    this.soundList.forEach(element => {
      if (element.id === item.id) {
        this.userData.soundPath = element.soundPath;
      }
    });
    localStorage.setItem('user', JSON.stringify(this.userData));
  }

  subscription() {
    const data = {
      recruiterId: this.userData.salesforceId,
      notificationType: this.showNotifications
    };
    this.store.dispatch(new NotificationSubscibe(data));
    this.userData.notification_subscribe = this.showNotifications;
    localStorage.setItem('user', JSON.stringify(this.userData));
  }
}
