export interface IInviteStudent {
  firstName: string;
  lastName: string;
  emailId: string;
  phone: string;
  recruiterId?: number;
  roleId?: number;
  university?: string;
  recruiterSalesforceId? : string;
}

export interface ICreateStudent {
  firstName: string;
  lastName: string;
  emailId: string;
  recruiterId?: number;
  university?: string;
  roleId?: number;
  recruiterSalesforceId? : string;
}

export interface IInviteRecruiter {
  firstName: string;
  lastName: string;
  emailId: string;
  recruiterId?: number;
  university?: string;
}

export interface IRecruiterLogin {
  emailId: string;
  password: string;
}

export interface IRecruiterSession {
  accessToken?: string;
  refreshToken: string;
  role: string;
  roleId: number;
  tokenId: number;
  userId: number;
  salesforceId: string;
  organizationId?:string;
  firstName?:string;
  category?: string
}

export interface IChangeRecruiterStatus {
  recruiterMasterId: number;
  recruiterId: number;
  status: string;
}

// export interface IRecruiterProfile {
//   id?: number;
//   firstName: string;
//   middleName:string;
//   lastName: string;
//   emailId: string;
//   companyName: string;
//   phone: string;
//   alternativeContact: string;
//   telephone: string;
//   cell: string;
//   website: string;
//   category: string;
//   country: string;
//   street: string;
//   city: string;
//   state: string;
//   zip: string;
//   countryOrigin: string;
//   recruitStudentsCount: string;
//   yearsInIndustry: string;
//   currentWorkingCountry: string;
//   serviceTypeToStudent: string;
//   agentServiceFee: string;
//   studentSendEveryYearNo: string;

//   recruiterCategory: string;
//   fbPageName: string;
//   instagramHandle: string;
//   twitterHandle: string;
//   linkedinURL: string;
//   sourceOfStudents: string;
//   skypeId: string;
//   whatsappId: string;
//   reference: string;
//   recruiterMaster: number;
//   recruitFrom: string;

//   studentRecruitBegin: string;
//   serviceProvide: string;
//   institutions: string;
//   canadianSchoolRepresented: boolean;
//   americanSchoolRepresented: boolean;
//   otherSchoolRepresented: boolean;
//   organizationBelongTo: string;
//   place: string;
//   studentsCount: string;
//   marketingMethods: string;
//   serviceFee: string;
//   studentReferCount: string;
//   comments: string;
//   referenceName: string;
//   referenceInstitutionName: string;
//   referenceBusinessEmail: string;
//   referencePhone: string;
//   referenceWebsite: string;
//   isAcknowledged: boolean;
//   salesforceId: string;
// }

export interface IStaffList {
  emailId: string;
  firstName: string;
  id: number;
  lastName: string;
  salesforceId: string;
  phone?: string;
  isPasswordSet?: boolean;
  status?: boolean;
}

export interface IStaff {
  firstName: string;
  lastName: string;
  emailId: string;
  roleId?: number;
  recruiterId?: number;
  recruiterSalesforceId?: string;
  university?: string;
  id?: number
}

export interface IChangeStaffStatus {
  staffId: number;
  isActive: boolean;
  firstName: string;
  lastName: string;
  emailId: string;
  salesforceId: string;
  recruiterId?: number;
  recruiterSalesforceId?: string;
}

export interface IDeleteStaff {
  id: number;
  recruiterId: number;
  salesforceId: string;
  recruiterSalesforceId?: string;
}

export interface ISwitchNotification {
  recruiterId: number;
  enableNotification: boolean;
}

export interface IBankingInformation {
  accountHolderName: string;
  accountHolderAddress: string;
  bankName: string;
  bankAddress: string;
  accountNumber: string;
  swiftCode: string;
  iban: string;
  recruiterId?: string,
  recruiterRoleId: number,
  ifscCode:String;
}

export interface IRecruiterRequest {
  id?: number;
  firstName: string;
  middleName:string;
  lastName: string;
  emailId: string;
  companyName: string;
  phone: string;
  website: string;
  country: string;
  street: string;
  territory: any;
  city: string;
  state: string;
  zip: string;
  category: string;
  studentSendEveryYearNo: string;
  studentCountSendToHub:string;
  recruiterCategory: string;
  fbPageName: string;
  instagramHandle: string;
  linkedinURL: string;
  whatsappId: string;
  studentsCount: string;
  isAcknowledged: boolean;
  salesforceId: string;
  aboutUniversity:string;
  businessCertificate?:string;
  businessCertificateType?:string;
  businessCertificateFilename?:string;
  experienceOverseasEducation:string;
  organizationId?:string;
  isDocumentUpload?:boolean
}

export interface ICommissionPolicy {
  university: string;
  ugcommission: number;
  pgcommission: number;
  phdcommission: number;
}

export enum ERecruiterEndPoint {
  ADD_RECRUITER = 'session/recruiterregister',
  ADD_RECRUITER_ORGANIZATION = 'session/recruiterRegisterOrganization',
  REGISTER_INVITED_RECRUITER_FREELANCER = 'session/registerInvitedRecruiterFreelancer',
  LOGIN_RECRUITER = 'session/loginRecruiterOrAssociate',
  GET_RECRUITER_PROFILE = 'recruiter/view?id=',
  EDIT_RECRUITER_FREELANCER_PROFILE = 'recruiter/editFreelancer',
  EDIT_RECRUITER_ORGANIZATION_PROFILE = 'recruiter/editOrganization',
  NOTIFY_STUDENT_COMPLETION = 'recruiter/notifyStudentCompletion',
  GET_RECRUITER_NOTIFICATION = 'notification/getRecruiterNotification',
  GET_RECRUITER_ASSOCIATE_NOTIFICATION = 'notification/getRecruiterAssociateNotification',
  SWITCH_NOTIFICATION = 'notification/switchRecruiterNotification',
  SWITCH_ASSOCIATE_NOTIFICATION = 'notification/switchRecruiterAssociateNotification',
  INVITE_RECRUITER = 'recruiter/inviteRecruiter',
  GET_RECRUITER_LIST = 'recruiter/getRecruiters',
  CHANGE_RECRUITER_STATUS = 'recruiter/changeRecruiterStatus',
  INVITE_STAFF = 'recruiter/inviteStaff',
  GET_STAFF_LIST = 'recruiter/getStaffs',
  CHANGE_STAFF_STATUS = 'recruiter/changeStaffStatus',
  DELETE_STAFF = 'recruiter/deleteStaff',
  UPDATE_STAFF_PROFILE = 'recruiter/updateStaffProfile',
  SAVE_BANKING_INFORMATION = 'recruiter/saveBankingInformation',
  GET_BANKING_INFORMATION = 'recruiter/getBankingInformation',
  GET_COMMISSION_POLICY = 'recruiter/getCommissionPolicy',
  UPDATE_RECRUITER_NOTIFICATION = 'notification/updateRecruiterNotification',
  UPDATE_RECRUITER_ASSOCIATE_NOTIFICATION = 'notification/updateRecruiterAssociateNotification',
  RE_ASSIGN_STUDENT = 'recruiter/reAssignStudent',
  GET_RECRUITER_STUDENT_SHORTLISTED_APPLICATION = 'recruiter/getRecruiterStudentShorlistedApplication',
  CHANGE_STUDENT_CATEGORY = 'recruiter/changeStudentCategory',
  LIST_ALL_SOUNDS = 'sounds/listSound',
  LIST_SOUNDS_ID = 'sounds/listSoundById?id=',
  UPDATE_RECRUITER_SOUND = 'recruiter/updateSound',
  UPDATE_NOTIFICATION_SUBSCRIPTION = 'recruiter/notificationSubscribe'
}

export enum ERecruiterCategory {
  Organization,
  Company,
  Employee,
  University
}
