import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ellis',
  templateUrl: './ellis.component.html',
  styleUrls: ['./ellis.component.scss']
})
export class EllisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
