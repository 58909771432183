export const environment = {
  production: false,
  baseUrl: 'https://recruiterapi.universityhub.com/studentUniversity/',
  // baseUrl: 'https://devapi.recruiter.universityhub.com/studentUniversity/',
  // baseUrl: 'http://localhost:3001/studentUniversity/',
  baseUrlCRM: 'https://crmservicesapi.universityhub.com/api/v1/',
  baseUrlCRMTwillio: 'https://crmtwilioapi.universityhub.com/api/',
  webURL: 'https://app.universityhub.com/',
  UH_PROJECTID: 7,
  TwilioNumber: '+16077033231',
  firebase: {
    apiKey: 'AIzaSyDXd86FG97SodBdz5W7BEh6z_apAdg86OM',
    authDomain: 'accessque-f4edb.firebaseapp.com',
    projectId: 'accessque-f4edb',
    storageBucket: 'accessque-f4edb.appspot.com',
    messagingSenderId: '991318304901',
    appId: '1:991318304901:web:c713749910d96d50daa1ec',
    measurementId: 'G-Z4Z5FYRBWB',
    vapidKey: 'BHN6imBr9X7fZxuXouWfk5DS3eQOi0oaMluvZ6l0AdCjOiq2fbkkFdzfq7lXAiSR1egqbY9MNH6ggN8lqPN_eEM'
  },

}


