import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/store/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileCompletionGuard  {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const user: any = this.userService.getLoggedInUser;
    localStorage.removeItem('isSeperateExploreUniversities');
    if (user) {
      if(localStorage.getItem('loginRole') == 'STUDENT'){
        if(user.isProfileCompleted == true){
          return true;
        } else {
          this.router.navigate(['/student-profile']);
        }
      } else {
        return true;
      }
    } else {
      return true
    }
    return true;
  }
  
}
